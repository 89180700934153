import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { apiPost } from '../../core/api.js'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../hooks/AuthContext'
import { Form, Button, Image, Row, Col } from 'react-bootstrap'

const LoginForm = () => {
  const { t } = useTranslation()
  const [formData, setFormData] = useState({ email: '', password: '' })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const { login, redirectToGoogle } = useContext(AuthContext)
  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }))
  }

  const validateFields = () => {
    const newErrors = {}

    if (formData.email.length === 0) {
      newErrors.email = t('login.validations.emailRequired')
    }

    if (formData.email.length > 0 && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t('login.validations.emailInvalid')
    }
    if (formData.password.length === 0) {
      newErrors.password = t('login.validations.passwordRequired')
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrors({}) // Reset errors before validation

    if (!validateFields()) return

    try {
      setLoading(true)
      const response = await apiPost('/signin', {
        email: formData.email,
        password: formData.password,
      })

      login(response?.data?.data?.token)
      setLoading(false)
      navigate('/')
    } catch (error) {
      setLoading(false)
      setErrors({ api: error.response?.data?.message || t('errors.500') })
    }
  }

  return (
    <>
      <div className="company-form mx-auto" style={{ marginTop: 80 }}>
        <h1 style={{ marginBottom: 16 }}>{t('login.loginHeader')}</h1>
        <p className="login-welcome-txt">{t('login.welcomeMessage')}</p>
        {errors.api && <p className="text-danger mt-3">{errors.api}</p>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formEmail">
            <Form.Label className="txt-label">
              {t('login.form.emailLabel')}
            </Form.Label>
            <Form.Control
              type="email"
              placeholder={t('login.form.emailPlaceholder')}
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={errors?.email ? 'error-txt-field ' : 'txt-field'}
            />
            {errors.email && <p className="error-msg">{errors.email}</p>}
          </Form.Group>

          <Form.Group controlId="formPassword">
            <Form.Label className="txt-label">
              {t('login.form.passwordLabel')}
            </Form.Label>
            <Form.Control
              type="password"
              placeholder={t('login.form.passwordPlaceholder')}
              name="password"
              value={formData.password}
              onChange={handleChange}
              className={errors?.password ? 'error-txt-field ' : 'txt-field'}
            />
            {errors.password && <p className="error-msg">{errors.password}</p>}
          </Form.Group>
          <div style={{ marginTop: '20px' }}>
            <Button className="submit-btn" type="submit">
              {loading ? t('btn.progress') : t('login.form.btnSignIn')}
            </Button>
          </div>
        </Form>
        <Button
          className="google-btn text-center "
          onClick={() => redirectToGoogle()}
          style={{ marginTop: 20 }}
        >
          <Image
            src="/images/google-btn.svg"
            width={18}
            height={18}
            alt={t('login.btn.google')}
          />
          <span className="google-btn-text">{t('login.btn.google')}</span>
        </Button>
        <hr className="section-seprator" />
        <Row className="signInUp-footer-div">
          <Col className="text-left" md={8}>
            <div className="div-signin-txt">{t('login.textNoAccount')}</div>
          </Col>
          <Col md={4}>
            <Button
              className="signInUp-footer-div-btn float-end"
              onClick={() => navigate('/signup')}
            >
              {t('login.btn.signup')}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default LoginForm
