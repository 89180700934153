import { useRef, useState, useEffect, useContext, Fragment } from 'react'
import { Button, Image } from 'react-bootstrap'
import { AuthContext } from '../../../hooks/AuthContext'
import {
  getGroupUserMaxAndMin,
  getGroupUserAverage,
  getGroupUserTotal,
  getUserName,
} from '../../../core/utils'

const Average = ({
  selectedUserIds,
  companyUsers,
  groupedData,
  type,
  filter,
  cardType,
  unit = '',
}) => {
  const { user } = useContext(AuthContext)
  const scrollContainerRef = useRef(null)
  const [isOverflowing, setIsOverflowing] = useState(false)

  useEffect(() => {
    const checkOverflow = () => {
      const container = scrollContainerRef.current
      if (container.scrollWidth > container.clientWidth) {
        setIsOverflowing(true)
      } else {
        setIsOverflowing(false)
      }
    }

    // Check for overflow on load and window resize
    checkOverflow()
    window.addEventListener('resize', checkOverflow)

    return () => window.removeEventListener('resize', checkOverflow)
  }, [])

  const getOperationResult = (data, user, type) => {
    if (type === 'range')
      return getGroupUserMaxAndMin(data, user.user_id, cardType, filter)
    if (type === 'average')
      return getGroupUserAverage(data, user.user_id, cardType, filter)
    if (type === 'total')
      return getGroupUserTotal(data, user.user_id, cardType, filter)
  }

  const scroll = (direction) => {
    const container = scrollContainerRef.current
    if (direction === 'left') {
      container.scrollBy({ left: -200, behavior: 'smooth' })
    } else if (direction === 'right') {
      container.scrollBy({ left: 200, behavior: 'smooth' })
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30 }}>
      {isOverflowing && (
        <Button
          variant="light"
          onClick={() => scroll('left')}
          style={{
            cursor: 'pointer',
            color: '#000',
            backgroundColor: 'transparent',
            padding: '10px',
            rotate: '180deg',
            border: 'none',
          }}
        >
          <Image src="/images/right-arrow.svg" />
        </Button>
      )}
      <div
        ref={scrollContainerRef}
        style={{
          display: 'flex',
          overflowX: 'auto',
          scrollBehavior: 'smooth',
          whiteSpace: 'nowrap',
          scrollbarWidth: 'none', // For Firefox
          msOverflowStyle: 'none', // For IE/Edge
        }}
        className="hide-scrollbar"
      >
        {user && user.user_id
          ? (() => {
              const data = getOperationResult(groupedData, user, type)

              return (
                <div
                  style={{
                    display: 'flex',
                    direction: 'row',
                    scrollBehavior: 'smooth',

                    whiteSpace: 'nowrap',
                  }}
                >
                  <div>
                    {selectedUserIds.length > 0 && (
                      <div className="health-card-username">
                        {getUserName(user)}
                      </div>
                    )}
                    <div className="healthCardAverage">
                      <>
                        {data === 0 || (data?.min === 0 && data?.max === 0)
                          ? `--`
                          : type === 'range'
                            ? `${data?.min} - ${data?.max} BPM`
                            : type == 'average'
                              ? `Average ${data}`
                              : `${data} Steps`}
                        {((unit && data > 0) ||
                          data?.min > 0 ||
                          data?.max > 0) &&
                          `\u00B0 ${unit}`}
                      </>
                    </div>
                  </div>
                  {selectedUserIds.length > 0 &&
                    companyUsers.length > 0 &&
                    companyUsers.map((companyUser, index) => {
                      const data = getOperationResult(
                        groupedData,
                        companyUser,
                        type,
                      )
                      return (
                        selectedUserIds.includes(companyUser.user_id) && (
                          <Fragment key={`userlist-${index}`}>
                            <div
                              style={{
                                margin: '0px 20px',
                                width: '1px',
                                border: '1px #EFEFEF solid',
                                color: '#fff',
                              }}
                            >
                              |
                            </div>
                            <div>
                              <div className="health-card-username">
                                {getUserName(companyUser)}
                              </div>

                              <div className="healthCardAverage">
                                {data === 0 ||
                                (data.min === 0 && data.max === 0)
                                  ? `--`
                                  : type === 'range'
                                    ? `${data?.min} - ${data?.max} BPM`
                                    : type === 'total'
                                      ? `${data} Steps`
                                      : `Average ${data}`}
                                {((unit && data > 0) ||
                                  data?.min > 0 ||
                                  data?.max > 0) &&
                                  `\u00B0 ${unit}`}
                              </div>
                            </div>
                          </Fragment>
                        )
                      )
                    })}
                </div>
              )
            })()
          : ''}
      </div>
      {isOverflowing && (
        <Button
          variant="light"
          onClick={() => scroll('right')}
          style={{
            cursor: 'pointer',
            color: '#000',
            backgroundColor: 'transparent',
            padding: '10px',
            border: 'none',
          }}
        >
          <Image src="/images/right-arrow.svg" />
        </Button>
      )}
    </div>
  )
}

export default Average
