import { Stack } from 'react-bootstrap'
import UserIcon from '../../core/components/UserIcon'
import { useContext, useMemo } from 'react'
import { AuthContext } from '../../hooks/AuthContext'
import { getUserName } from '../../core/utils/user.js'
import { capitalize, renderValue } from '../../core/utils'

const ProfileView = () => {
  const { user } = useContext(AuthContext)
  const username = useMemo(() => getUserName(user), [user])

  const fullName = useMemo(() => {
    const firstName = capitalize(user?.firstName)
    const lastName = capitalize(user?.lastName)

    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    } else if (firstName || lastName) {
      return firstName || lastName
    }
    return '-'
  }, [user])

  return (
    <div className="mx-auto profile-container">
      <Stack gap={3}>
        <UserIcon username={username} size={80} backgroundcolor="#F8DEBD" />
        <div className="profile-name">
          {renderValue(fullName)}
          <div className="profile-email">{renderValue(user?.email)}</div>
        </div>

        <div className="profile-header">Personal details</div>

        {[
          { title: 'Name', value: fullName },
          { title: 'Email address', value: user?.email },
          { title: 'Community', value: user?.communities?.[0]?.name },
          { title: 'Mobile number', value: user?.phoneNumber },
        ].map(({ title, value }, index) => (
          <div key={index}>
            <div className="profile-field-title">{title}</div>
            <div
              className={`profile-field-value ${
                index < 3 ? 'profile-field-value-border' : ''
              }`}
            >
              {renderValue(value)}
            </div>
          </div>
        ))}
      </Stack>
    </div>
  )
}

export default ProfileView
