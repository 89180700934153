import { memo } from 'react'
import { useEffect, useReducer, useCallback, useMemo, useContext } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import HealthTopBar from '../core/components/layouts/HealthTopBar.jsx'
import HealthFilterBar from '../features/Health/HealthFilterBar.jsx'
import HealthTab from '../features/Health/HealthTab.jsx'
import Loader from '../core/components/Loader'
import { AuthContext } from '../hooks/AuthContext'
import { CompareUsersDialog } from '../features/Health/CompareUsersDialog.jsx'
import { getHealthSections } from '../features/Health/Cards/health-section.js'
import {
  fetchCommunityUsers,
  fetchHealthData,
  importHealthData,
} from '../features/Health/healthApi.js'

// add memonization
const MemoizedHealthFilterBar = memo(HealthFilterBar)
const MemoizedCompareUsersDialog = memo(CompareUsersDialog)
const MemoizedHealthTopBar = memo(HealthTopBar)
const MemoizedHealthTab = memo(HealthTab)
const MemoizedLoader = memo(Loader)

// Initial State
const initialState = {
  loading: false,
  communityUsers: [],
  selectedUserIds: [],
  healthData: [],
  filter: { value: new Date(), type: 'date' },
  showCompareUsers: false,
}

// Reducer to manage state
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    case 'SET_COMMUNITY_USERS':
      return { ...state, communityUsers: action.payload }
    case 'SET_SELECTED_USER_IDS':
      return { ...state, selectedUserIds: action.payload }
    case 'SET_HEALTH_DATA':
      return { ...state, healthData: action.payload }
    case 'SET_FILTER':
      return { ...state, filter: action.payload }
    case 'TOGGLE_COMPARE_USERS':
      return { ...state, showCompareUsers: action.payload }
    default:
      return state
  }
}

const Health = () => {
  const { user } = useContext(AuthContext)
  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    loading,
    communityUsers,
    selectedUserIds,
    healthData,
    filter,
    showCompareUsers,
  } = state

  // Load Community Users
  const loadCommunityUsers = useCallback(async () => {
    if (!user?._id) return
    dispatch({ type: 'SET_LOADING', payload: true })

    const communityId = user.communities?.[0]?._id
    const users = await fetchCommunityUsers(user._id, communityId)
    dispatch({ type: 'SET_COMMUNITY_USERS', payload: users })
    dispatch({ type: 'SET_LOADING', payload: false })
  }, [user?._id, user?.communities])

  // Load Health Data
  const loadHealthData = useCallback(
    async (user, filter, selectedUserIds = []) => {
      if (!user?._id) return
      dispatch({ type: 'SET_LOADING', payload: true })

      const data = await fetchHealthData(user._id, filter, selectedUserIds)
      dispatch({ type: 'SET_HEALTH_DATA', payload: data })
      dispatch({ type: 'SET_LOADING', payload: false })
    },
    [],
  )

  const exportHealthData = useCallback(async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    const res = await importHealthData(user._id, filter, selectedUserIds)
    dispatch({ type: 'SET_LOADING', payload: false })
    if (!res) {
      alert('No data available for the selected date')
    }
  }, [filter, selectedUserIds, user])

  // Memoized Health Sections
  const healthSections = useMemo(
    () =>
      getHealthSections(healthData, selectedUserIds, communityUsers, filter),
    [healthData, selectedUserIds, communityUsers, filter],
  )

  // Load community users on mount
  useEffect(() => {
    loadCommunityUsers()
  }, [loadCommunityUsers])

  // Load health data when filter, user, or selectedUserIds change
  useEffect(() => {
    if (filter && user?._id) {
      loadHealthData(user, filter, selectedUserIds)
    }
  }, [filter, user, selectedUserIds, loadHealthData])

  if (loading) {
    return <MemoizedLoader />
  }

  return (
    <Container fluid className="m-0 p-0" style={{ background: '#FFFCF8' }}>
      {/* Top Bar and Filter */}
      <Container
        fluid
        className="m-0 menuBar"
        style={{
          top: 0,
          width: 'calc(100% - 90px)',
          position: 'fixed',
          background: '#FFFCF8',
          zIndex: 1000,
          padding: 50,
          paddingBottom: 0,
        }}
      >
        <MemoizedHealthTopBar />
        <MemoizedHealthFilterBar
          filter={filter}
          setFilter={(value) =>
            dispatch({ type: 'SET_FILTER', payload: value })
          }
          setShowCompareUsers={(value) =>
            dispatch({ type: 'TOGGLE_COMPARE_USERS', payload: value })
          }
          communityUsers={communityUsers}
          selectedUserIds={selectedUserIds}
          exportHealthData={exportHealthData}
        />
        <MemoizedHealthTab
          heartDataRef="heartDataRef"
          sleepDataRef="sleepDataRef"
          oxygenLevelsRef="oxygenLevelsRef"
          bodyTempRef="bodyTempRef"
          stepsTakenRef="stepsTakenRef"
        />
        <div className="health-tab-divider"></div>
      </Container>

      {/* Health Sections Content */}
      <Container
        fluid
        className="m-0 staticContent"
        style={{ padding: 50, paddingTop: 370 }}
      >
        {healthSections.map(({ id, title, cards }) => (
          <Row key={id} style={{ marginBottom: 50 }} id={id}>
            <Col md={12} style={{ marginBottom: 30 }}>
              <span className="heathCardHeading" id={id}>
                {title}
                <Image
                  src="/images/arrow-down.svg"
                  className="ms-2"
                  style={{ cursor: 'pointer' }}
                />
              </span>
            </Col>
            {cards.map((CardComponent, index) => (
              <Col key={index} md={cards.length === 2 ? '6' : '12'}>
                {CardComponent}
              </Col>
            ))}
          </Row>
        ))}

        {/* Compare Users Dialog */}
        <Row style={{ marginBottom: 220 }}>
          <Col>
            <MemoizedCompareUsersDialog
              showCompareUsers={showCompareUsers}
              setShowCompareUsers={(value) =>
                dispatch({ type: 'TOGGLE_COMPARE_USERS', payload: value })
              }
              communityUsers={communityUsers}
              selectedUserIds={selectedUserIds}
              setSelectedUserIds={(ids) =>
                dispatch({ type: 'SET_SELECTED_USER_IDS', payload: ids })
              }
            />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Health
