import React, { useEffect } from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../hooks/AuthContext'
import { Container } from 'react-bootstrap'
import TopBar from '../core/components/layouts/TopBar'
import LoginForm from '../features/login/LoginForm.jsx'

const Login = () => {
  const { token } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (token && token.length > 0) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <Container className="community-form-container mx-auto " fluid>
      <TopBar className={'topnav text-center '} />
      <LoginForm />
    </Container>
  )
}

export default Login
