import React from 'react'
import { Image } from 'react-bootstrap'

const UserIcon = ({
  username,
  imageUrl = null,
  backgroundcolor = '#ccc',
  size = 50,
}) => {
  // Generate initials from the username
  const getInitials = (name) => {
    const initials =
      name && name.length > 0
        ? name
            .split(' ')
            .map((part) => part[0])
            .join('')
        : ''
    return initials.toUpperCase()
  }

  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: imageUrl ? 'transparent' : backgroundcolor,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: size * 0.4,
        color: '#fff',
        overflow: 'hidden',
      }}
    >
      {imageUrl ? (
        <Image
          src={imageUrl}
          alt="User icon"
          roundedCircle
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      ) : (
        <span>{getInitials(username)}</span>
      )}
    </div>
  )
}

export default UserIcon
