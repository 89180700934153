import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import VerificationForm from '../features/verification/VerificationForm'
import TopBar from '../core/components/layouts/TopBar'
import Stepper from '../core/components/Stepper'

const Verficiation = () => {
  const { t } = useTranslation()
  return (
    <Container className="community-form-container mx-auto " fluid>
      <TopBar className={'topnav text-center '} />
      <div className="text-center mx-auto community-form-wrapper">
        <Stepper currentStep={1} />
      </div>
      <VerificationForm />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '100px',
        }}
      >
        <p className="dashboard-footer">
          {t('dashboard.footerEmailMessage')}
          <a href="mailto:rav@yeyro.com">rav@yeyro.com</a>
        </p>
      </div>
    </Container>
  )
}

export default Verficiation
