import { Container } from 'react-bootstrap'
import HealthTopBar from '../core/components/layouts/HealthTopBar'
import UserList from '../features/users/UserList'

const Users = () => {
  return (
    <Container
      fluid
      className="m-0"
      style={{
        background: '#FFFCF8',
        height: '100vh',
        padding: '50px',
      }}
    >
      <HealthTopBar title="Users" />
      <UserList />
    </Container>
  )
}
export default Users
