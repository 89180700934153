import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { apiPost } from '../../core/api.js'
import { AuthContext } from '../../hooks/AuthContext'
import { Form, Button } from 'react-bootstrap'

const CompanyForm = () => {
  const { t } = useTranslation()
  const [formData, setFormData] = useState({
    companyName: '',
    industry: '',
  })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const { loadCurrentUser } = useContext(AuthContext)
  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData((prevData) => ({ ...prevData, [name]: value }))
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }))
  }

  const validateFields = () => {
    const newErrors = {}

    if (formData.companyName.length === 0) {
      newErrors.companyName = t('company.validations.oganisationRequired')
    }

    if (formData.industry.length === 0) {
      newErrors.industry = t('company.validations.industryRequired')
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!validateFields()) return
    try {
      setLoading(true)
      await apiPost('user/company', {
        companyName: formData.companyName,
        industry: formData.industry,
      })

      await loadCurrentUser()
      navigate('/')
    } catch (error) {
      setErrors({ api: error.response?.data?.message || t('errors.500') })
    }
  }

  return (
    <div className="company-form mx-auto">
      <h1 style={{ marginBottom: 16 }}>{t('company.setupEnterprise')}</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formOrganisation">
          <Form.Label className="txt-label">
            {t('company.form.companyField')}
          </Form.Label>
          <Form.Control
            type="text"
            name="companyName"
            placeholder={t('company.form.companyField')}
            value={formData.companyName}
            onChange={handleChange}
            className={errors?.companyName ? 'error-txt-field ' : 'txt-field'}
          />
          {errors?.companyName && (
            <p className="error-msg">{errors.companyName}</p>
          )}
        </Form.Group>
        {/* <Form.Group controlId="formIndustry">
          <Form.Label className="txt-label">Industry</Form.Label>
          <Form.Control
            type="text"
            name="industry"
            placeholder="Industry"
            value={formData.industry}
            onChange={handleChange}
            className={errors?.industry ? 'error-txt-field ' : 'txt-field'}
          />
          {errors?.industry && <p className="error-msg">{errors.industry}</p>}
        </Form.Group> */}
        <Form.Group controlId="formIndustry">
          <Form.Label className="txt-label">
            {t('company.form.industryField')}
          </Form.Label>
          <Form.Select
            name="industry"
            value={formData.industry}
            onChange={handleChange}
            aria-label="Select Industry"
            className={errors?.industry ? 'error-txt-field ' : 'txt-field'}
          >
            <option value="">{t('company.form.industryPlaceholder')}</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Transportation">Transportation</option>
            <option value="Construction">Construction</option>
            <option value="Mining">Mining</option>
            <option value="Software">Software</option>
            <option value="Employee Wellbeing">Employee Wellbeing</option>
            <option value="Insurance">Insurance</option>
            <option value="Others">Others</option>
          </Form.Select>
          {errors?.industry && <p className="error-msg">{errors.industry}</p>}
        </Form.Group>
        <div style={{ marginTop: '20px' }}>
          <Button className="submit-btn" type="submit">
            {loading ? t('btn.progress') : t('btn.continue')}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default CompanyForm
