import { Container } from 'react-bootstrap'
import CommunityForm from '../features/community/CommunityForm'
import TopBar from '../core/components/layouts/TopBar'
import Stepper from '../core/components/Stepper'

const Community = () => (
  <Container className="community-form-container mx-auto " fluid>
    <TopBar className={'topnav text-center '} showLogout={true} />
    <div className="text-center mx-auto community-form-wrapper">
      <Stepper currentStep={2} />
    </div>
    <CommunityForm />
  </Container>
)

export default Community
