import { Form, Button, Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { apiPost } from '../../../core/api.js'
import { useTranslation } from 'react-i18next'
import useForm from '../../../core/components/common/hooks/useForm'
import FormInput from '../../../core/components/common/form/FormInput'
import { AppStateContext } from '../../../hooks/AppStateContext.js'

const SignupForm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { updateAuthFlow } = useContext(AppStateContext)

  const validate = (data) => {
    const errors = {}
    if (!data.firstName)
      errors.firstName = t('signup.validations.firstNameRequired')
    if (!data.lastName)
      errors.lastName = t('signup.validations.lastNameRequired')
    if (!data.email) errors.email = t('signup.validations.emailRequired')
    if (data.email && !/\S+@\S+\.\S+/.test(data.email))
      errors.email = t('signup.validations.emailInvalid')
    return errors
  }

  const {
    formData,
    errors,
    handleChange,
    validateFields,
    loading,
    setLoading,
    setErrors,
  } = useForm({ firstName: '', lastName: '', email: '' }, validate)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrors({})
    if (!validateFields()) return

    try {
      setLoading(true)
      await apiPost('v2/auth/signup', formData)
      updateAuthFlow('signUp')
      navigate(`/verification?email=${formData.email}`)
      navigate(`/verification?email=${formData.email}`)
    } catch (error) {
      setErrors({ api: error.response?.data?.data?.message || t('errors.500') })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {errors.api && <p className="error-msg mt-3">{errors.api}</p>}

      <Container className="m-0 p-0">
        <Row>
          <Col>
            <FormInput
              label={t('signup.form.firstNameLabel')}
              name="firstName"
              placeholder={t('signup.form.firstNamePlaceholder')}
              value={formData.firstName}
              onChange={handleChange}
              error={errors.firstName}
            />
          </Col>
          <Col>
            <FormInput
              label={t('signup.form.lastNameLabel')}
              name="lastName"
              placeholder={t('signup.form.lastNamePlaceholder')}
              value={formData.lastName}
              onChange={handleChange}
              error={errors.lastName}
            />
          </Col>
        </Row>
      </Container>

      <FormInput
        label={t('signup.form.emailLabel')}
        name="email"
        type="email"
        placeholder={t('signup.form.emailPlaceholder')}
        value={formData.email}
        onChange={handleChange}
        error={errors.email}
      />

      <div className="div-submit">
        <Button className="submit-btn" type="submit">
          {loading ? t('btn.progress') : t('btn.continue')}
        </Button>
      </div>
    </Form>
  )
}

export default SignupForm
