import { useContext, useEffect } from 'react'
import { AuthContext } from '../hooks/AuthContext'
import { useNavigate } from 'react-router-dom'
import { Container, Image, Button } from 'react-bootstrap'
import TopBar from '../core/components/layouts/TopBar'

const Dashboard = () => {
  const { user, isAppLoading } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (
      !isAppLoading &&
      user &&
      (user.company_name === null || user.company_name === '')
    ) {
      navigate('/company')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppLoading, user])

  if (isAppLoading) {
    return <div>Loading...</div>
  }

  return (
    <>
      {user && (
        <Container
          className="dashboard-form-container mx-auto "
          fluid
          style={{
            margin: 0,
            padding: 0,
            backgroundImage: 'url(images/Background.svg)',
            backgroundSize: 'cover',
            height: '100%',
          }}
        >
          <TopBar className={'topnav'} showLogout={true} />
          <div
            className="text-center mx-auto"
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 95,
              maxWidth: 660,
            }}
          >
            <Image src="/images/ring.svg" width={68} height={68} alt="Ring" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p className="dashboard-text">
              We are thrilled for you to start using our wearables platform.
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p className="dashboard-subtext">
              The first step to start using the platform is to get access to our
              smart rings.
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p className="dashboard-subtext">
              You can buy our smart rings on our online store here
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Button
              className="submit-btn"
              type="submit"
              style={{ maxWidth: 350 }}
              onClick={() =>
                window.open(
                  'https://www.yeyroring.com/?selectedProduct=7867677671562',
                )
              }
            >
              Shop now
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '200px',
            }}
          >
            <p className="dashboard-footer">
              If you&apos;re feeling stuck - email us at rav@yeyro.com
            </p>
          </div>
        </Container>
      )}
    </>
  )
}

export default Dashboard
