import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../../hooks/AuthContext'
import Loader from './Loader'

const ProtectedRoute = ({ children }) => {
  const { user, isAppLoading } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAppLoading && !user) {
      navigate('/signup')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAppLoading])

  if (isAppLoading) {
    return <Loader />
  }

  return children
}

export default ProtectedRoute
