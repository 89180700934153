import { apiGet } from '../../core/api'

// Load Community Users
export const fetchCommunityUsers = async (userId, communityId) => {
  if (!userId || !communityId) return []

  try {
    const res = await apiGet(`v2/community/${communityId}/users`)
    if (res?.data?.data?.users?.length) {
      return res.data.data.users.filter(
        (communityUser) => communityUser._id !== userId,
      )
    }
    return []
  } catch (error) {
    console.error('Error loading community users:', error)
    return []
  }
}

// Utility function to generate URL for health data
const getHealthDataUrl = (
  userId,
  filter,
  selectedUserIds = [],
  isExport = false,
) => {
  const userIds = [userId, ...selectedUserIds].join(',')
  const value =
    filter.type === 'date' ? new Date(filter.value).toISOString() : filter.value

  const baseUrl = isExport
    ? `v2/health/web/export/filter` // For CSV import
    : `v2/health/web/filter` // For JSON data
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const params = {
    userIds,
    filterType: filter.type,
    filterValue: value,
  }
  if (!isExport) {
    params.timeZone = timeZone
  }
  const queryParams = new URLSearchParams(params).toString()
  return `${baseUrl}?${queryParams}`
}

// Load Health Data (JSON)
export const fetchHealthData = async (userId, filter, selectedUserIds = []) => {
  if (!userId) return []

  const url = getHealthDataUrl(userId, filter, selectedUserIds)

  try {
    const res = await apiGet(url)
    return res?.data?.data || []
  } catch (error) {
    console.error('Error loading health data:', error)
    return []
  }
}

// Import Health Data (Download CSV)
export const importHealthData = async (
  userId,
  filter,
  selectedUserIds = [],
) => {
  if (!userId) return []

  const url = getHealthDataUrl(userId, filter, selectedUserIds, true)

  try {
    // Get CSV response as blob
    const res = await apiGet(url, { responseType: 'blob' })
    const contentType = res?.headers?.['content-type']

    // Check if the response is a CSV
    if (
      contentType &&
      contentType.includes(
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      )
    ) {
      // Trigger download of CSV file
      downloadExcelFile(res.data)
    }
    return true
  } catch (error) {
    console.error('Error importing health data:', error)
    return false
  }
}

// Utility to download excel file in browser
const downloadExcelFile = (blob) => {
  try {
    // filename should be unique
    const fileName = `health-data-${new Date().toISOString()}.xlsx`
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error triggering CSV download:', error)
  }
}
