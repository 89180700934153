import { useContext } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'
import { AuthContext } from '../../../../hooks/AuthContext'
import 'bootstrap/dist/css/bootstrap.min.css'
import PopoverComponent from './PopoverComponent' // Import your common Popover component

const SideBar = () => {
  const { logout } = useContext(AuthContext)
  const location = useLocation()
  const currentPath = location.pathname

  const menuItems = [
    {
      key: 'dashboard',
      path: '/health',
      image:
        currentPath === '/health' || currentPath === '/'
          ? '/images/sidebar/selected/home.svg'
          : '/images/sidebar/home.svg',
      content: 'Dashboard',
      className:
        currentPath === '/health' || currentPath === '/'
          ? 'sidebar-selected-menu'
          : 'sidebar-menu',
    },
    {
      key: 'users',
      path: '/users',
      image:
        currentPath === '/users'
          ? '/images/sidebar/selected/user.svg'
          : '/images/sidebar/user.svg',
      content: 'Users',
      className:
        currentPath === '/users' ? 'sidebar-selected-menu' : 'sidebar-menu',
    },
    {
      key: 'devices',
      path: '/devices',
      image:
        currentPath === '/devices'
          ? '/images/sidebar/selected/device.svg'
          : '/images/sidebar/device.svg',
      content: 'Devices',
      className: '',
    },
    {
      key: 'logout',
      action: logout,
      image: '/images/sidebar/logout.svg',
      content: 'Logout',
      className: 'sidebar-menu',
    },
  ]

  return (
    <div className="sidebar-container">
      <ListGroup variant="flush" className="sidebar-list-group">
        <ListGroup.Item className="p-0 m-0 list-group-item-home">
          <Link to="/">
            <img
              className="sidebar-icons sidebar-home-icon"
              alt=""
              src="/images/logo.svg"
            />
          </Link>
        </ListGroup.Item>
        {menuItems.map((item) => (
          <ListGroup.Item className="p-0 m-0" key={item.key}>
            <PopoverComponent content={item.content}>
              {item.action ? (
                <Link onClick={item.action}>
                  <img className={item.className} alt="" src={item.image} />
                </Link>
              ) : (
                <Link to={item.path}>
                  <img alt="" className={item.className} src={item.image} />
                </Link>
              )}
            </PopoverComponent>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  )
}

export default SideBar
