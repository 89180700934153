import React from 'react'
import { useTranslation } from 'react-i18next'
import '../../../scss/Stepper.scss'

const Stepper = ({ currentStep }) => {
  const { t } = useTranslation()
  return (
    <div className="stepper-container">
      <div
        className={`step step-1 ${currentStep >= 1 ? 'active' : ''}`}
        style={{}}
      >
        <div className="circle" />
        <p className="label">{t('verification.title')}</p>
      </div>

      <div className={`line ${currentStep >= 2 ? 'active' : ''}`} />

      <div className={`step step-2 ${currentStep >= 2 ? 'active' : ''}`}>
        <div className="circle" />
        <p className="label">{t('signup.onBoarding')}</p>
      </div>
    </div>
  )
}

export default Stepper
