import Spinner from 'react-bootstrap/Spinner'
import { Container, Row, Col } from 'react-bootstrap'

const Loader = () => {
  return (
    <Container
      className="d-flex justify-content-center 
    align-items-center common-pages vh-100 "
      fluid
    >
      <Row>
        <Col className="col-md-12 text-center">
          <Spinner
            animation="border"
            style={{ width: '5rem', height: '5rem' }}
          />
          <span className="visually-hidden">Loading...</span>
        </Col>
      </Row>
    </Container>
  )
}

export default Loader
