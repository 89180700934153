import { createContext, useState } from 'react'

const AppStateContext = createContext()

const AppStateProvider = ({ children }) => {
  const [authFlow, setAuthFlow] = useState(null) // 'signIn' or 'signUp'

  // Set auth flow to either 'signIn' or 'signUp'
  const updateAuthFlow = (flow) => {
    setAuthFlow(flow)
  }

  // Clear auth flow on logout or reset
  const clearAuthFlow = () => {
    setAuthFlow(null)
  }

  // get auth flow
  const getAuthFlow = () => {
    return authFlow
  }

  return (
    <AppStateContext.Provider
      value={{
        authFlow,
        updateAuthFlow,
        clearAuthFlow,
        getAuthFlow,
      }}
    >
      {children}
    </AppStateContext.Provider>
  )
}

export { AppStateContext, AppStateProvider }
