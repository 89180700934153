import { useContext, useMemo } from 'react'
import { AuthContext } from '../../../hooks/AuthContext'
import { Card } from 'react-bootstrap'
import { AreaChart } from './ChartJs/AreaChart'
import { LineChart } from './ChartJs/LineChart'
import Average from './Average'
import {
  filterDataByType,
  transformAndGroupData,
} from '../../../core/utils/graphs'

const SpO2Card = ({ data, selectedUserIds, communityUsers, filter }) => {
  const { user: currentUser } = useContext(AuthContext)

  // Memoize filtered SpO2 data
  const spo2Data = useMemo(
    () => filterDataByType(data, 'blood_oxygen', 'bloodOxygen', filter.type),
    [data, filter.type],
  )

  // Memoize transformed and grouped data
  const { groupedData, userIdArray } = useMemo(
    () => transformAndGroupData(spo2Data, filter.type),
    [spo2Data, filter.type],
  )

  // Memoize chart type selection
  const ChartComponent = useMemo(
    () => (filter.type === 'date' ? AreaChart : LineChart),
    [filter.type],
  )

  return (
    <Card className="healthCardContainer">
      <Card.Body>
        <Card.Title className="">
          <div className="healthCardTitle">Oxygen Levels</div>
          <Average
            selectedUserIds={selectedUserIds}
            communityUsers={communityUsers}
            groupedData={groupedData}
            type="average"
            filter={filter}
            cardType="oxygen"
          />
        </Card.Title>
        {spo2Data && spo2Data.length > 0 ? (
          <ChartComponent
            data={groupedData}
            userIdArray={userIdArray}
            communityUsers={communityUsers}
            currentUser={currentUser}
            labelUnit="%"
          />
        ) : (
          <div className="noData">No data available for the selected date</div>
        )}
      </Card.Body>
    </Card>
  )
}

export default SpO2Card
