import React from 'react'
import './Stepper.css'

const Stepper = ({ currentStep }) => {
  return (
    <div className="stepper-container">
      <div
        className={`step ${currentStep >= 1 ? 'active' : ''}`}
        style={{
          position: 'relative',
          top: '17px',
          left: '26px',
        }}
      >
        <div className="circle" />
        <p className="label">Sign up</p>
      </div>
      <div className={`line ${currentStep >= 2 ? 'active' : ''}`} />
      <div
        className={`step ${currentStep >= 2 ? 'active' : ''}`}
        style={{
          position: 'relative',
          top: '17px',
          right: '36px',
        }}
      >
        <div className="circle" />
        <p className="label">Verification</p>
      </div>
    </div>
  )
}

export default Stepper
