import { useEffect, useState, useCallback } from 'react'
import { Container } from 'react-bootstrap'
import HealthTopBar from '../core/components/layouts/HealthTopBar'
import { apiGet } from '../core/api'
import Loader from '../core/components/Loader'

const Devices = () => {
  const [loading, setLoading] = useState(false)
  const [usersDevices, setUsersDevices] = useState([])

  const loadUserDevice = useCallback(async () => {
    setLoading(true)
    apiGet(`/user/company/device`)
      .then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          setUsersDevices(res.data.data)
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false) // Always stop loading after attempt
      })
  }, [])

  useEffect(() => {
    loadUserDevice()
  }, [loadUserDevice])

  return (
    <Container
      fluid
      className="m-0"
      style={{
        background: '#FFFCF8',
        height: '100vh',
        padding: '50px',
      }}
    >
      <HealthTopBar title="Devices" />
      <div>
        {loading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div className="table-container">
            <table className="table list-table table-responsive">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Device Name</th>
                  <th>DeviceId</th>
                  <th>Device Firmware</th>
                  <th>Last Used</th>
                </tr>
              </thead>
              <tbody>
                {usersDevices.map((device) => (
                  <tr key={device?.user_client}>
                    <td>{`${device?.user?.firstname} ${device?.user?.lastname}`}</td>
                    <td>{device?.ring_name}</td>
                    <td>{device?.user_client}</td>
                    <td>V{device?.hardware}</td>
                    <td>
                      {new Date(device?.user?.lastUsed).toLocaleDateString(
                        'en-US',
                        {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        },
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Container>
  )
}
export default Devices
