import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../../hooks/AuthContext'
import { Card, Button } from 'react-bootstrap'
import { AreaChart } from './ChartJs/AreaChart'
import { LineChart } from './ChartJs/LineChart'
import Average from './Average'
import {
  formatAndSortTimeData,
  filterDataByType,
  transformAndGroupData,
} from '../../../core/utils'

const TemperatureCard = ({
  timmingData,
  selectedUserIds,
  companyUsers,
  filter,
}) => {
  const { user: currentUser } = useContext(AuthContext)
  const [unit, setUnit] = useState('C') // Default to Celsius
  const [userIdArray, setUserIdArray] = useState([])
  const [formatedTemperatureData, setFormatedTemperatureData] = useState([])

  const toggleUnit = () => {
    setUnit((prevUnit) => (prevUnit === 'C' ? 'F' : 'C'))
  }

  useEffect(() => {
    const temperatureData = filterDataByType(timmingData, 3, filter.type, unit)
    const formattedData = formatAndSortTimeData(temperatureData, filter.type)
    const { groupedData, userIdArray } = transformAndGroupData(
      formattedData,
      filter.type,
    )
    setUserIdArray(userIdArray)
    setFormatedTemperatureData(groupedData)
  }, [unit, timmingData, filter.type])

  return (
    <Card className="healthCardContainer">
      <Card.Body>
        <Card.Title className=" tempratureTitle">
          <span className="tempratureLabel">
            <div className="healthCardTitle">Body Temperature</div>
            <Average
              selectedUserIds={selectedUserIds}
              companyUsers={companyUsers}
              groupedData={formatedTemperatureData}
              type="average"
              filter={filter}
              cardType="temperature"
              unit={unit}
            />
          </span>
          <Button variant="" onClick={toggleUnit} className="tempratureToggle">
            <span
              className={
                unit !== 'C'
                  ? 'tempratureToggleButtons tempratureToggleActive'
                  : 'tempratureToggleButtons'
              }
            >
              F
            </span>
            <span
              style={{ padding: '2px 5px', cursor: 'pointer', margin: '0 2px' }}
              className={
                unit === 'C'
                  ? 'tempratureToggleButtons tempratureToggleActive'
                  : 'tempratureToggleButtons'
              }
            >
              C
            </span>
          </Button>
        </Card.Title>
        {formatedTemperatureData && formatedTemperatureData.length > 0 ? (
          filter.type === 'date' ? (
            <AreaChart
              data={formatedTemperatureData}
              userIdArray={userIdArray}
              type={'temperature'}
              companyUsers={companyUsers}
              currentUser={currentUser}
            />
          ) : (
            <LineChart
              data={formatedTemperatureData}
              userIdArray={userIdArray}
              type={'temperature'}
              companyUsers={companyUsers}
              currentUser={currentUser}
            />
          )
        ) : (
          <div className="noData">No data available for the selected date</div>
        )}
      </Card.Body>
    </Card>
  )
}

export default TemperatureCard
