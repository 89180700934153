import { useEffect, useContext, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { apiPost } from '../../core/api.js'
import { AuthContext } from '../../hooks/AuthContext.jsx'
import { Form, Button } from 'react-bootstrap'
import SmallLoader from '../../core/components/SmallLoader'
import useForm from '../../core/components/common/hooks/useForm'
import OtpInput from 'react-otp-input'
import { isEmptyObject } from '../../core/utils/index.js'

const VerificationForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { login } = useContext(AuthContext)

  const validate = useCallback(
    (values) => {
      const errors = {}
      if (!values.otp || values.otp.length < 6) {
        errors.otp = t('validations.required')
      }
      return errors
    },
    [t],
  )

  const {
    formData,
    errors,
    loading,
    setLoading,
    setErrors,
    setField,
    message,
    setMessage,
    validateFields,
  } = useForm({ otp: '', email: '' }, validate)

  const handleOtpChange = useCallback(
    (otp) => {
      if (errors?.otp || errors?.api || message) {
        setErrors({})
        setMessage('')
      }
      setField('otp', otp)
    },
    [errors, message, setErrors, setField, setMessage],
  )

  const verifyOTP = useCallback(
    async (payload) => {
      if (loading) return
      try {
        setLoading(true)
        const response = await apiPost('v2/auth/verify-otp', payload)
        login(response.data.data.token)
        navigate(`/`)
      } catch (error) {
        setErrors({
          api: error.response?.data?.data?.message || t('errors.500'),
        })
      } finally {
        setLoading(false)
      }
    },
    [loading, login, navigate, setErrors, setLoading, t],
  )

  const resendOTP = useCallback(async () => {
    try {
      setField('otp', '')
      setErrors({})
      setLoading(true)
      const response = await apiPost('v2/auth/web-signin', {
        email: formData.email,
      })
      setMessage(response?.data?.data?.message)
    } catch (error) {
      setErrors({
        api: error.response?.data?.data?.message || t('errors.500'),
      })
    } finally {
      setLoading(false)
    }
  }, [formData.email, setErrors, setField, setLoading, setMessage, t])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      if (!validateFields()) return
      verifyOTP({ otp: formData.otp, email: formData.email })
    },
    [formData, validateFields, verifyOTP],
  )

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const email = urlParams.get('email')
    if (email) setField('email', email)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="verfication-form mx-auto">
      {loading && <SmallLoader />}
      <h1 style={{ marginBottom: 16 }}>{t('verification.header')}</h1>

      {errors.api && <p className="text-danger mt-3">{errors.api}</p>}
      {message && <p className="text-success mt-3">{message}</p>}

      <Form onSubmit={handleSubmit}>
        {/* Email Input */}
        <Form.Group controlId="formOrganisation">
          <Form.Label className="txt-label">
            {t('verification.form.emailLabel')}
          </Form.Label>
          <Form.Control
            type="text"
            name="email"
            disabled
            value={formData.email}
            placeholder={formData.email}
            className="txt-field disabled-input"
          />
        </Form.Group>

        <p className="confirmation-txt">
          {t('verification.form.confirmation-txt')}
        </p>

        {/* OTP Input */}
        <Form.Group controlId="formOrganisation">
          <Form.Label className="txt-label">
            {t('verification.form.codeLabel')}
          </Form.Label>
          <div className="code-input-wrapper">
            <OtpInput
              value={formData.otp}
              name="otp"
              onChange={handleOtpChange}
              numInputs={6}
              renderSeparator={<span className="code-space"></span>}
              renderInput={(props) => <input disabled={loading} {...props} />}
              inputType="tel"
              hasDisabled={true}
              inputStyle={!isEmptyObject(errors) ? 'code-input-error' : ''}
              shouldAutoFocus={true}
            />
          </div>
          {errors.otp && <p className="text-danger mt-3">{errors.otp}</p>}
        </Form.Group>

        {/* Submit Button */}
        <div style={{ marginTop: '20px' }}>
          <Button className="submit-btn" type="submit" disabled={loading}>
            {loading ? t('btn.progress') : t('btn.continue')}
          </Button>
        </div>

        {/* Resend OTP */}
        <p className="resend-txt">
          {t('verification.form.notReceivedTxt')}{' '}
          <span className="resend-txt-bold" onClick={resendOTP}>
            {t('verification.form.sendAgainLinkTxt')}
          </span>
        </p>
      </Form>
    </div>
  )
}

export default VerificationForm
