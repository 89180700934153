import { createContext, useState, useEffect } from 'react'

import { apiGet } from '../core/api.js'
import {
  getFromLocalStorage,
  setFromLocalStorage,
  clearFromLocalStorage,
} from '../core/utils/localStorageService.js'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)
  const [isAppLoading, setIsAppLoading] = useState(true) // Start as true to block UI until auth check is complete

  useEffect(() => {
    const initAuth = async () => {
      setIsAppLoading(true) // Ensure loading is set at the beginning
      const storedToken = getFromLocalStorage('token')

      if (storedToken && storedToken.length > 0) {
        setToken(storedToken)
        await loadCurrentUser(storedToken) // Await user loading
      } else {
        setIsAppLoading(false) // No token, stop loading
      }
    }

    initAuth()
  }, [])

  const loadCurrentUser = async (token) => {
    try {
      const res = await apiGet('v2/user', {
        headers: { Authorization: `Bearer ${token}` },
      })
      setUser(res?.data?.data?.user)
    } catch (error) {
      setUser(null)
      clearFromLocalStorage('token')
      setToken(null)
    } finally {
      setIsAppLoading(false) // Always stop loading after attempt
    }
  }

  const login = async (token) => {
    setIsAppLoading(true)
    setFromLocalStorage('token', token)
    setToken(token)
    await loadCurrentUser(token)
    setIsAppLoading(false) // Ensure loading state updates properly
  }

  const logout = () => {
    clearFromLocalStorage('token')
    setToken(null)
    setUser(null)
  }

  const redirectToGoogle = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/v2/auth/oauth/google`
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        redirectToGoogle,
        login,
        logout,
        isAppLoading,
        token,
        loadCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
