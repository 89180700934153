import { useContext, useEffect } from 'react'
import { AuthContext } from '../../../../hooks/AuthContext'
import { useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import SideBar from './SideBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../../../scss/health.scss'
import Loader from '../../Loader'

const MainLayout = ({ children }) => {
  const { user, isAppLoading } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (
      !isAppLoading &&
      user &&
      (!user?.communities || user?.communities?.length === 0)
    ) {
      navigate('/community')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppLoading, user])

  if (isAppLoading) {
    return <Loader />
  }
  return (
    <Container fluid className="m-0 p-0">
      <Row className="m-0 p-0 gx-0 ">
        <Col className="sidebar m-0 p-0 ">
          <SideBar />
        </Col>
        <Col className="m-0 p-0">{children}</Col>
      </Row>
    </Container>
  )
}

export default MainLayout
