import { Form } from 'react-bootstrap'

const FormInput = ({
  label,
  name,
  type = 'text',
  placeholder,
  value,
  onChange,
  error,
  style,
}) => (
  <Form.Group controlId={`form${name}`}>
    <Form.Label className="txt-label">{label}</Form.Label>
    <Form.Control
      type={type}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      className={error ? 'error-txt-field' : 'txt-field'}
      style={{ ...style }}
    />
    {error && <p className="error-msg">{error}</p>}
  </Form.Group>
)

export default FormInput
