import { DateTime } from 'luxon'

export const formatAndSortTimeData = (data, filterType = 'date') =>
  data && data.length > 0
    ? data
        .map((item) => {
          let start = ''

          if (filterType === 'date') {
            start = formateDate(item.test_time, 'HH:mm a')
          } else {
            start = formateDate(item.test_time, 'LLL dd')
          }

          let formatedData = {}
          if (filterType === 'date') {
            formatedData = {
              value: item.val,
            }
          } else {
            formatedData = {
              min_value: item.min_value,
              max_value: item.max_value,
              total_value: item.total_value,
            }
          }

          return {
            ...formatedData,
            time: start,
            orignalTime: item.test_time,
            user_id: item.user_id,
          }
        })
        .sort((a, b) => new Date(a.orignalTime) - new Date(b.orignalTime)) // Sort by test_time
    : []

export const transformAndGroupData = (data, filterType = 'date') => {
  const uniqueUserIds = new Set()
  const groupedData = data.reduce((acc, item) => {
    const {
      time: test_time,
      user_id,
      value,
      min_value,
      max_value,
      total_value,
    } = item
    uniqueUserIds.add(user_id)

    // Find or create the time group
    let timeGroup = acc.find((entry) => entry.time === test_time)
    if (!timeGroup) {
      timeGroup = { time: test_time }
      acc.push(timeGroup)
    }

    // Add the user's value for this time
    if (filterType === 'date') {
      timeGroup[user_id] = value
    } else {
      timeGroup[user_id] = {
        min_value: min_value,
        max_value: max_value,
        total_value: total_value,
      }
    }

    return acc
  }, [])

  // Step 2: Sort by `time` in ascending order
  // groupedData.sort((a, b) => new Date(a.time) - new Date(b.time))
  const userIdArray = Array.from(uniqueUserIds)
  return { groupedData, userIdArray }
}

export const filterDataByType = (
  data,
  type,
  filterType = 'date',
  unit = 'C',
) => {
  if (!data || data.length === 0) {
    return []
  }

  return data
    .filter((item) => item.type === type)
    .map((item) => {
      if (type === 3 && unit) {
        // Apply conversion logic only for type 3
        if (filterType === 'date') {
          const newValue = unit === 'F' ? (item.val * 9) / 5 + 32 : item.val // Convert if needed
          return {
            ...item,
            val: newValue, // Update `val` with the converted value
          }
        } else {
          console.log('item filter', filterType, type, unit)
          return {
            ...item,
            min_value:
              unit === 'F' ? (item.min_value * 9) / 5 + 32 : item.min_value,
            max_value:
              unit === 'F' ? (item.max_value * 9) / 5 + 32 : item.max_value,
            total_value:
              unit === 'F' ? (item.total_value * 9) / 5 + 32 : item.total_value,
          }
        }
      }
      return item
    })
}

export const stageMapping = {
  Awake: 4,
  REM: 3,
  Shallow: 2,
  Deep: 1,
}

const formateDate = (datetime, format) => {
  return DateTime.fromISO(datetime, { zone: 'utc' }).toFormat(format)
}

export const sortAndConvertSleepDataByTime = (stages) =>
  stages
    .map((stage) => {
      const start = formateDate(stage.start, 'HH:mm a')
      const end = formateDate(stage.end, 'HH:mm a')
      const fullDate = formateDate(stage.start, 'yyyy-MM-dd HH:mm:ss')

      return {
        id: stage.id,
        stage: stageMapping[stage.stage] || null,
        stageString: stage.stage,
        time: start,
        end,
        fullDate,
        orignalStartTime: stage.start,
        orignalEndTime: stage.end,
        inout_val: stage.inout_val,
        activ_val: stage.activ_val,
      }
    })
    .sort((a, b) => {
      return new Date(a.orignalTime) - new Date(b.orignalTime)
    })

export const getRange = (data) => {
  if (!data || data.length === 0) {
    return { min: 0, max: 0 } // Handle empty or undefined data
  }

  let min = parseInt(data[0].val) // Initialize with the first element's value
  let max = parseInt(data[0].val)

  data.forEach((element) => {
    if (parseInt(element.val) < min) {
      min = element.val
    }
    if (parseInt(element.val) > max) {
      max = element.val
    }
  })

  return { min, max }
}

export const getGroupUserMaxAndMin = (data, userId, cardType, filter) => {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return { max: 0, min: 0 }
  }

  let min = 0
  let max = 0

  data.forEach((element) => {
    const value =
      filter.type === 'date'
        ? element[userId]
        : cardType === 'steps'
          ? element[userId]?.total_value
          : element[userId]?.min_value
    if (element[userId] && !min) min = value
    if (element[userId] && !max) max = value

    if (value && parseInt(value) < min) {
      min = value
    }
    if (value && parseInt(value) > max) {
      max = value
    }
  })

  return { min, max }
}
export const getGroupUserTotal = (data, userId, cardType, filter) => {
  const filteredData = data.filter((entry) => entry[userId] !== undefined)

  const values = filteredData.map((element) => {
    const value =
      filter.type === 'date'
        ? element[userId]
        : cardType === 'steps'
          ? element[userId]?.total_value
          : element[userId]?.min_value

    return Number(value)
  })
  const sum =
    values && values.length > 0
      ? values.reduce((acc, value) => acc + value, 0)
      : 0
  return sum
}
export const getGroupUserAverage = (data, userId, cardType, filter) => {
  const filteredData = data.filter((entry) => entry[userId] !== undefined)
  const values = filteredData.map((element) => {
    const value =
      filter.type === 'date'
        ? element[userId]
        : cardType === 'steps'
          ? element[userId]?.total_value
          : element[userId]?.min_value

    return Number(value)
  })
  const sum =
    values && values.length > 0
      ? values.reduce((acc, value) => acc + value, 0)
      : 0

  return values && values.length > 0 ? Math.floor(sum / values.length) : 0
}

export const getTotal = (data) => {
  if (!data || data.length === 0) {
    return 0 // Handle empty or undefined data
  }

  return data.reduce((sum, element) => sum + parseInt(element.val), 0)
}

export const getAverage = (data) => {
  if (!data || data.length === 0) {
    return 0 // Handle empty or undefined data
  }

  const total = data.reduce((sum, element) => sum + parseInt(element.val), 0)
  return Math.floor(total / data.length)
}

export const scrollToSection = (ref) => {
  const section = document.querySelector(`#${ref}`)
  const menuBarHeight = document.querySelector('.menuBar').offsetHeight

  window.scrollTo({
    top: section.offsetTop - 50 - menuBarHeight,
    behavior: 'smooth',
  })
}

export const transformMutlipleDaysData = (rawData) => {
  return rawData.map((entry) => {
    const transformedEntry = { time: entry.time }
    Object.keys(entry).forEach((key) => {
      if (key !== 'time') {
        transformedEntry[`${key}_min`] = parseFloat(entry[key].min_value)
        transformedEntry[`${key}_max`] = parseFloat(entry[key].max_value)
        transformedEntry[`${key}_total`] = parseFloat(entry[key].total_value)
      }
    })
    return transformedEntry
  })
}

export const getUserNameFromCompanyArray = (userId, companyUsers) => {
  const user = companyUsers.find((user) => user.user_id === userId)
  return getUserName(user)
}

export const getUserName = (user) => {
  return user
    ? user?.firstname
      ? user?.firstname
      : user?.email?.substring(0, user?.email.lastIndexOf('@'))
    : ''
}

export const getUserColor = (index) => {
  let color = ''
  if (index === 0) {
    color = '#F3A53F'
  } else if (index === 1) {
    color = '#9878FF'
  } else {
    color = `#${Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, '0')}`
  }
  return color
}
