import Offcanvas from 'react-bootstrap/Offcanvas'
import { getUserName } from '../../core/utils'

export const CompareUsersDialog = ({
  showCompareUsers,
  setShowCompareUsers,
  companyUsers,
  selectedUserIds,
  filter,
  user,
  setSelectedUserIds,
  loadHealthData,
}) => {
  const handleCheckboxChange = (id) => {
    setSelectedUserIds((prevSelected) => {
      if (prevSelected.includes(id)) {
        // Remove ID if already selected
        return prevSelected.filter((userId) => userId !== id)
      } else {
        // Add ID if not already selected
        return [...prevSelected, id]
      }
    })
  }
  return (
    <>
      <Offcanvas
        show={showCompareUsers}
        onHide={() => setShowCompareUsers(false)}
        placement="end"
      >
        <Offcanvas.Body
          style={{
            padding: '90px 30px',
          }}
        >
          <div>
            <div className="user-list-header">User List</div>
            <div className="user-list-details">
              Select users to See Insights
            </div>
            {companyUsers.map((user) => (
              <div className="user-list" key={user.id}>
                <div style={{ paddingBottom: 20 }}>
                  <div className="user-list-element">
                    <input
                      type="checkbox"
                      value={user.user_id}
                      checked={selectedUserIds.includes(user.user_id)}
                      onChange={() => handleCheckboxChange(user.user_id)}
                      style={{
                        border: '1px solid #D9D9D9 !important',
                        boxShadow: '0px 1px 1px 1px #D9D9D9',
                        height: 20,
                        width: 20,
                        top: 4,
                        position: 'relative',
                        marginRight: 6,
                      }}
                    />{' '}
                    {getUserName(user)}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button
            className="user-list-compare-button"
            onClick={() => {
              loadHealthData(user, filter, selectedUserIds)
              setShowCompareUsers(false)
            }}
            style={{}}
          >
            Select
          </button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
