// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stepper-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  padding: 10px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.step-1 {
  top: 17px;
  left: 35px;
}

.step-2 {
  top: 17px;
  right: 30px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #a9a7ae;
}

.step.active .circle {
  background-color: #000;
}

.label {
  font-family: Questrial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #a9a7ae;
  margin-top: 4px;
}

.step.active .label {
  color: #000;
}

/* Adjusted line to show 50% progress */
.line {
  flex-grow: 1;
  height: 2px;
  background: linear-gradient(to right, #000 50%, #a9a7ae 50%);
}

.line.active {
  background: linear-gradient(to right, #000 100%, #a9a7ae 0%);
}`, "",{"version":3,"sources":["webpack://./src/scss/Stepper.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AACA;EACE,SAAA;EACA,UAAA;AAEF;;AAAA;EACE,SAAA;EACA,WAAA;AAGF;;AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;AAGF;;AAAA;EACE,sBAAA;AAGF;;AAAA;EACE,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;AAGF;;AAAA;EACE,WAAA;AAGF;;AAAA,uCAAA;AACA;EACE,YAAA;EACA,WAAA;EACA,4DAAA;AAGF;;AACA;EACE,4DAAA;AAEF","sourcesContent":[".stepper-container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 300px;\n  padding: 10px;\n}\n\n.step {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  position: relative;\n}\n.step-1 {\n  top: 17px;\n  left: 35px;\n}\n.step-2 {\n  top: 17px;\n  right: 30px;\n}\n\n.circle {\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background-color: #a9a7ae;\n}\n\n.step.active .circle {\n  background-color: #000;\n}\n\n.label {\n  font-family: Questrial;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  color: #a9a7ae;\n  margin-top: 4px;\n}\n\n.step.active .label {\n  color: #000;\n}\n\n/* Adjusted line to show 50% progress */\n.line {\n  flex-grow: 1;\n  height: 2px;\n  background: linear-gradient(to right, #000 50%, #a9a7ae 50%);\n\n}\n\n.line.active {\n  background: linear-gradient(to right, #000 100%, #a9a7ae 0%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
