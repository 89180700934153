import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MainLayout from './core/components/layouts/Twocolumn/MainLayout'
import ProtectedRoute from './core/components/ProtectedRoute'

import Company from './pages/Company'
import { AuthProvider } from './hooks/AuthContext'
import Dashboard from './pages/Dashboard'
import LoginSuccess from './pages/LoginSuccess'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import NotFound from './pages/NotFound'
import HealthDashboard from './features/Health/HealthDashboard'
import Profile from './pages/Profile'
import Users from './pages/Users'
import Devices from './pages/Devices'

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route>
            <Route path="/login-success" element={<LoginSuccess />} />
            <Route path="/signin" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company"
              element={
                <ProtectedRoute>
                  <Company />
                </ProtectedRoute>
              }
            />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/users"
              element={
                <MainLayout>
                  <Users />
                </MainLayout>
              }
            />
            <Route
              path="/devices"
              element={
                <MainLayout>
                  <Devices />
                </MainLayout>
              }
            />

            <Route
              path="/health"
              element={
                <MainLayout>
                  <HealthDashboard />
                </MainLayout>
              }
            />

            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  )
}

export default App
