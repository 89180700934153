import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../hooks/AuthContext'
import Loader from '../core/components/Loader'

const LoginSuccess = () => {
  const { login, isAppLoading } = useContext(AuthContext)
  const [token, setToken] = useState('')
  const navigate = useNavigate()

  // if code in query then get token from query and set in context
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')

    if (code && code.length > 0) {
      setToken(code)
    }
  }, [])

  useEffect(() => {
    const handleLogin = async () => {
      if (token && token.length > 0) {
        await login(token) // Wait for login to complete
        navigate('/') // Navigate after login completes
      }
    }
    handleLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  if (isAppLoading) {
    return <Loader />
  }
  return null
}
export default LoginSuccess
