import { useContext } from 'react'
import { AuthContext } from '../../../hooks/AuthContext'
import { Card } from 'react-bootstrap'
import { AreaChart } from './ChartJs/AreaChart'
import { LineChart } from './ChartJs/LineChart'
import Average from './Average'
import {
  formatAndSortTimeData,
  filterDataByType,
  transformAndGroupData,
} from '../../../core/utils'

const SpO2Card = ({ timmingData, selectedUserIds, companyUsers, filter }) => {
  const { user: currentUser } = useContext(AuthContext)
  const SpO2Data = filterDataByType(timmingData, 2, filter.type)
  const formatedSpO2Data = formatAndSortTimeData(SpO2Data, filter.type)
  const { groupedData, userIdArray } = transformAndGroupData(
    formatedSpO2Data,
    filter.type,
  )

  return (
    <Card className="healthCardContainer">
      <Card.Body>
        <Card.Title className="">
          <div className="healthCardTitle">Oxygen Levels</div>
          <Average
            selectedUserIds={selectedUserIds}
            companyUsers={companyUsers}
            groupedData={groupedData}
            type="average"
            filter={filter}
            cardType="oxygen"
          />
        </Card.Title>
        {formatedSpO2Data && formatedSpO2Data.length > 0 ? (
          filter.type === 'date' ? (
            <AreaChart
              data={groupedData}
              userIdArray={userIdArray}
              type={'oxygen'}
              companyUsers={companyUsers}
              currentUser={currentUser}
            />
          ) : (
            <LineChart
              data={groupedData}
              userIdArray={userIdArray}
              type={'oxygen'}
              companyUsers={companyUsers}
              currentUser={currentUser}
            />
          )
        ) : (
          <div className="noData">No data available for the selected date</div>
        )}
      </Card.Body>
    </Card>
  )
}

export default SpO2Card
