import { useRef, useState } from 'react'
import { Image, Dropdown } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateTime } from 'luxon'

const FilteDropDownButton = ({
  leftImage,
  Text,
  handleButtonClick,
  filter,
  setFilter,
}) => {
  const datepickerRef = useRef(null)
  const handleDateIconClick = () => {
    datepickerRef.current.setOpen(true)
  }
  const [showDropdown, setShowDropdown] = useState(false)

  return (
    <Dropdown
      show={showDropdown}
      onToggle={(isOpen) => setShowDropdown(isOpen)}
      className=""
    >
      <div
        style={{
          paddingLeft: 14,
          paddingRight: 14,
          paddingTop: 10,
          paddingBottom: 10,
          background: 'white',
          boxShadow: '0px 1px 50px rgba(0, 0, 0, 0.02)',
          borderRadius: 12,
          border: '1px #E8E7E7 solid',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
          display: 'inline-flex',
          cursor: handleButtonClick ? 'pointer' : 'default',
        }}
        onClick={handleButtonClick ? handleButtonClick : null}
      >
        <div style={{ width: 24, height: 24 }}>
          <Image
            src={`${leftImage}`}
            style={{ cursor: 'pointer' }}
            onClick={handleDateIconClick}
          />
          <DatePicker
            selected={filter.type === 'date' ? filter.value : null}
            onChange={(date) => setFilter({ type: 'date', value: date })}
            dateFormat="dd/MM/yyyy"
            ref={datepickerRef}
            customInput={<div />} // Hide default input
          />
        </div>

        <div
          style={{
            color: '#383838',
            fontSize: 14,
            fontFamily: 'Inter',
            fontWeight: '400',
            wordWrap: 'break-word',
          }}
        >
          {Text === '30'
            ? 'Last 30 days'
            : Text === '7'
              ? 'Last 7 days'
              : DateTime.fromFormat(Text, 'dd/MM/yyyy').toFormat(
                  'MMMM d, yyyy',
                )}
        </div>

        <Dropdown.Toggle
          id="dropdown-basic"
          variant="outline-secondary"
          className="d-flex align-items-left health-date-dropdown"
          onClick={(e) => {
            e.preventDefault()
            setShowDropdown(!showDropdown)
          }}
          size="lg"
        ></Dropdown.Toggle>

        <Dropdown.Menu style={{ padding: 20 }}>
          <Dropdown.Item className="custom-dropdown-item">
            <input
              className="drop-down-radio"
              type="radio"
              checked={filter?.option === 1}
              onChange={() =>
                setFilter({ value: new Date(), type: 'date', option: 1 })
              }
            ></input>
            Today
          </Dropdown.Item>
          <Dropdown.Item className="custom-dropdown-item">
            <input
              className="drop-down-radio"
              type="radio"
              checked={filter?.option === 2}
              onChange={() =>
                setFilter({ value: '7', type: 'days', option: 2 })
              }
            ></input>
            Last 7 days
          </Dropdown.Item>
          <Dropdown.Item
            className="custom-dropdown-item"
            style={{ cursor: 'default' }}
          >
            <input
              className="drop-down-radio"
              type="radio"
              checked={filter?.option === 3}
              onChange={() =>
                setFilter({ value: '30', type: 'days', option: 3 })
              }
            ></input>
            Last 30 days
          </Dropdown.Item>
          {/* <Dropdown.Item
            onClick={() => setFilter({ value: 'last', type: 'month' })}
          >
            Last Month
          </Dropdown.Item> */}
        </Dropdown.Menu>
      </div>
    </Dropdown>
  )
}
export default FilteDropDownButton
