// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stepper-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  padding: 10px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #a9a7ae;
}

.step.active .circle {
  background-color: #000;
}

.label {
  font-family: Questrial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #a9a7ae;
  margin-top: 4px;
}

.step.active .label {
  color: #000;
}

/* Adjusted line to show 50% progress */
.line {
  flex-grow: 1;
  height: 2px;
  background: linear-gradient(to right, #000 50%, #a9a7ae 50%);
  margin: 0 10px;
}

.line.active {
  background-color: #000;
}
`, "",{"version":3,"sources":["webpack://./src/core/components/Stepper/Stepper.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA,uCAAuC;AACvC;EACE,YAAY;EACZ,WAAW;EACX,4DAA4D;EAC5D,cAAc;AAChB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".stepper-container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 300px;\n  padding: 10px;\n}\n\n.step {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n\n.circle {\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background-color: #a9a7ae;\n}\n\n.step.active .circle {\n  background-color: #000;\n}\n\n.label {\n  font-family: Questrial;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  color: #a9a7ae;\n  margin-top: 4px;\n}\n\n.step.active .label {\n  color: #000;\n}\n\n/* Adjusted line to show 50% progress */\n.line {\n  flex-grow: 1;\n  height: 2px;\n  background: linear-gradient(to right, #000 50%, #a9a7ae 50%);\n  margin: 0 10px;\n}\n\n.line.active {\n  background-color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
