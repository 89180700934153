import React, { useMemo } from 'react'
import { Line } from 'react-chartjs-2'

import { transformMutlipleDaysData } from '../../../../core/utils/graphs'
import {
  getUserNameFromCommunityArray,
  getUserName,
} from '../../../../core/utils/user.js'
import { getUserColor } from '../../../../core/utils/graphs'
import './ChartJsInit.js'

// Create dataset for max and min values
const createDataset = (label, data, color) => ({
  label,
  data,
  borderColor: color,
  borderWidth: 1,
  backgroundColor: color,
  tension: 0.4,
  fill: false,
  pointRadius: 3,
  pointBackgroundColor: '#fff',
  pointBorderColor: color,
})

export const LineChart = ({
  data,
  userIdArray,
  communityUsers,
  currentUser,
  labelUnit = '',
}) => {
  // Memoize transformed line data
  const lineData = useMemo(() => transformMutlipleDaysData(data), [data])

  // Memoize datasets to avoid reprocessing
  const datasets = useMemo(() => {
    return userIdArray.flatMap((userId, index) => {
      const username =
        getUserNameFromCommunityArray(userId, communityUsers) ||
        getUserName(currentUser)

      const color = getUserColor(userId, index)

      return [
        createDataset(
          `${username} (Max)`,
          lineData.map((item) => item[`${userId}_max`] || null),
          color,
        ),
        createDataset(
          `${username} (Min)`,
          lineData.map((item) => item[`${userId}_min`] || null),
          color,
        ),
      ]
    })
  }, [userIdArray, communityUsers, currentUser, lineData])

  // Memoize chart data
  const chartData = useMemo(
    () => ({
      labels: lineData.map((item) => item.time),
      datasets,
    }),
    [lineData, datasets],
  )

  // Memoize chart options
  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          backgroundColor: '#fff',
          titleColor: '#000',
          bodyColor: '#000',
          cornerRadius: 10,
          boxPadding: 5,
          borderColor: '#E8E7E7',
          borderWidth: 1,
          bodySpacing: 5,
          padding: 10,
          boxWidth: 8,
          boxHeight: 8,
          callbacks: {
            title: (tooltipItems) => {
              return `Date: ${tooltipItems[0].label}`
            },
            label: (tooltipItem) => {
              const datasetLabel = tooltipItem.dataset.label || 'User'
              const value =
                tooltipItem.raw !== null ? tooltipItem.raw : 'No data'
              return `${datasetLabel}: ${value}${labelUnit}`
            },
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x', // Enable horizontal panning
          },
          zoom: {
            wheel: {
              enabled: true, // Enable zooming with mouse wheel
            },
            pinch: {
              enabled: true, // Enable zooming with touch gestures
            },
            mode: 'x', // Enable horizontal zooming
          },
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              size: 10,
            },
            callback: function (value) {
              return this.getLabelForValue(value)
            },
          },
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            callback: (value) => `${value} ${labelUnit.toString().trim()}`, // Append unit to Y-axis values
          },
        },
      },
    }),
    [labelUnit],
  )

  return (
    <div style={{ width: '100%', height: '250px' }}>
      <Line data={chartData} options={options} />
    </div>
  )
}
