import React, { useContext, useMemo } from 'react'
import { Card } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'
import {
  filterDataByType,
  transformAndGroupData,
  transformMutlipleDaysData,
} from '../../../core/utils/graphs'
import {
  getUserNameFromCommunityArray,
  getUserName,
} from '../../../core/utils/user.js'
import { getUserColor } from '../../../core/utils/graphs'

import Average from './Average'
import { AuthContext } from '../../../hooks/AuthContext.jsx'
import './ChartJs/ChartJsInit.js'

const StepsCard = ({ data, selectedUserIds, communityUsers, filter }) => {
  const { user: currentUser } = useContext(AuthContext)

  // Memoize step data based on filter type
  const stepData = useMemo(
    () => filterDataByType(data, 'steps', 'steps', filter.type),
    [data, filter.type],
  )

  // Memoize grouped and transformed data
  const { groupedData, userIdArray } = useMemo(
    () => transformAndGroupData(stepData, filter.type),
    [stepData, filter.type],
  )

  const transformedData = useMemo(
    () =>
      filter.type !== 'date'
        ? transformMutlipleDaysData(groupedData)
        : groupedData,
    [groupedData, filter.type],
  )

  // Memoize chart datasets
  const datasets = useMemo(
    () =>
      userIdArray.map((userId, index) => {
        let username = getUserNameFromCommunityArray(userId, communityUsers)
        if (!username || username.length === 0) {
          username = getUserName(currentUser)
        }

        const dataKey = filter.type !== 'date' ? `${userId}_total` : `${userId}`
        const color = getUserColor(userId, index)
        return {
          label: `${username}`,
          data: transformedData.map((entry) => entry[dataKey] || 0),
          backgroundColor: color,
          borderColor: color,
          borderWidth: 1,
        }
      }),
    [userIdArray, communityUsers, currentUser, transformedData, filter.type],
  )

  // Memoize chart data
  const chartData = useMemo(
    () => ({
      labels: transformedData.map((entry) => entry.time),
      datasets,
    }),
    [transformedData, datasets],
  )

  // Memoize chart options
  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      maxBarThickness: 20,
      minBarLength: 0,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          backgroundColor: '#fff',
          titleColor: '#000',
          bodyColor: '#000',
          cornerRadius: 10,
          boxPadding: 5,
          borderColor: '#E8E7E7',
          borderWidth: 1,
          bodySpacing: 5,
          padding: 10,
          boxWidth: 8,
          boxHeight: 8,
          callbacks: {
            title: (tooltipItems) => {
              const lablePrefix = filter.type === 'date' ? 'Time' : 'Date'

              return `${lablePrefix}: ${tooltipItems[0].label}`
            },
            label: (tooltipItem) => {
              const datasetLabel = tooltipItem.dataset.label || 'User'
              const value =
                tooltipItem.raw !== null ? tooltipItem.raw : 'No data'
              return `${datasetLabel}: ${value} Steps`
            },
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'x',
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Time',
          },
          grid: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            maxRotation: 45,
            minRotation: 0,
            font: {
              size: 14,
            },
          },
        },
        y: {
          title: {
            display: true,
            text: 'Steps',
          },
        },
      },
    }),
    [],
  )

  return (
    <Card className="healthCardContainer">
      <Card.Body>
        <Card.Title className="healthCardTitle">
          <div className="healthCardTitle">Steps Taken</div>
          <Average
            selectedUserIds={selectedUserIds}
            communityUsers={communityUsers}
            groupedData={groupedData}
            type="total"
            filter={filter}
            cardType="steps"
          />
        </Card.Title>
        {stepData && stepData.length > 0 ? (
          <div style={{ height: '250px', width: '100%' }}>
            <Bar data={chartData} options={options} />
          </div>
        ) : (
          <div className="noData">No data available for the selected date</div>
        )}
      </Card.Body>
    </Card>
  )
}

export default StepsCard
