import { useEffect, useContext, useState, useCallback } from 'react'
import { Container } from 'react-bootstrap'
import { AuthContext } from '../hooks/AuthContext'
import HealthTopBar from '../core/components/layouts/HealthTopBar'
import { apiGet } from '../core/api'
import Loader from '../core/components/Loader'

const Users = () => {
  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [companyUsers, setCompanyUsers] = useState([])

  const loadCompanyUsers = useCallback(async (user) => {
    setLoading(true)
    apiGet(`user/company/users`)
      .then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          // exclude current user
          const filteredUsers = res.data.data.filter(
            (companyUser) => companyUser.user_id !== user.user_id,
          )
          setCompanyUsers(filteredUsers)
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false) // Always stop loading after attempt
      })
  }, [])

  useEffect(() => {
    loadCompanyUsers(user)
  }, [user, loadCompanyUsers])

  return (
    <Container
      fluid
      className="m-0"
      style={{
        background: '#FFFCF8',
        height: '100vh',
        padding: '50px',
      }}
    >
      <HealthTopBar title="Users" />
      <div>
        {loading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div className="table-container">
            <table className="table list-table table-responsive">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {companyUsers.map((user) => (
                  <tr key={user.user_id}>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td>{user.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Container>
  )
}
export default Users
