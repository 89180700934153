import React, { useContext } from 'react'
import { Card } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'
import {
  formatAndSortTimeData,
  filterDataByType,
  transformAndGroupData,
  transformMutlipleDaysData,
  getUserNameFromCompanyArray,
  getUserName,
} from '../../../core/utils'
import Average from './Average'
import { AuthContext } from '../../../hooks/AuthContext.jsx'
import 'chart.js/auto'

const StepsCard = ({ timmingData, selectedUserIds, companyUsers, filter }) => {
  const { user: currentUser } = useContext(AuthContext)

  const formattedStepsData = formatAndSortTimeData(
    filterDataByType(timmingData, 4, filter.type),
    filter.type,
  )

  const { groupedData, userIdArray } = transformAndGroupData(
    formattedStepsData,
    filter.type,
  )

  const transformedData =
    filter.type !== 'date'
      ? transformMutlipleDaysData(groupedData)
      : groupedData

  const getColor = (index) => {
    if (index === 0) return '#F3A53F'
    if (index === 1) return '#9878FF'
    return `#${Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, '0')}`
  }

  const chartData = {
    labels: transformedData.map((entry) => entry.time),
    datasets: userIdArray.map((userId, index) => {
      let username = getUserNameFromCompanyArray(userId, companyUsers)
      if (username.length === 0) {
        username = getUserName(currentUser)
      }
      return {
        label: `${username} Total`,
        data: transformedData.map(
          (entry) =>
            entry[`${filter.type !== 'date' ? `${userId}_total` : userId}`] ||
            0,
        ),
        backgroundColor: getColor(index),
        borderColor: getColor(index),
        borderWidth: 1,
      }
    }),
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    maxBarThickness: 20,
    minBarLength: 0,

    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}`,
        },
        mode: 'index',
        intersect: false,
        backgroundColor: '#fff',
        titleColor: '#000',
        bodyColor: '#000',
        cornerRadius: 10,
        boxPadding: 5,
        borderColor: '#E8E7E7',
        borderWidth: 1,
        bodySpacing: 5,
        padding: 10,
        boxWidth: 8,
        boxHeight: 8,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x', // Enable horizontal panning
        },
        zoom: {
          wheel: {
            enabled: true, // Enable zooming with mouse wheel
          },
          pinch: {
            enabled: true, // Enable zooming with touch gestures
          },
          mode: 'x', // Enable horizontal zooming
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Time',
        },
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxRotation: 45,
          minRotation: 0,
          font: {
            size: 14,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Steps',
        },
      },
    },
  }

  return (
    <Card className="healthCardContainer">
      <Card.Body>
        <Card.Title className="healthCardTitle">
          <div className="healthCardTitle">Steps Taken</div>
          <Average
            selectedUserIds={selectedUserIds}
            companyUsers={companyUsers}
            groupedData={groupedData}
            type="total"
            filter={filter}
            cardType="steps"
          />
        </Card.Title>
        {formattedStepsData && formattedStepsData.length > 0 ? (
          <div style={{ height: '250px', width: '100%' }}>
            <Bar data={chartData} options={options} />
          </div>
        ) : (
          <div className="noData">No data available for the selected date</div>
        )}
      </Card.Body>
    </Card>
  )
}

export default StepsCard
