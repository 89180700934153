import { useRef, useState, useCallback, useMemo } from 'react'
import { Image, Dropdown } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateTime } from 'luxon'
import React from 'react'

const DROPDOWN_OPTIONS = [
  { label: 'Today', value: new Date(), type: 'date', option: 1 },
  { label: 'Last 7 days', value: '7', type: 'days', option: 2 },
  { label: 'Last 30 days', value: '30', type: 'days', option: 3 },
]

const containerStyle = {
  padding: '10px 14px',
  background: 'white',
  boxShadow: '0px 1px 50px rgba(0, 0, 0, 0.02)',
  borderRadius: 12,
  border: '1px #E8E7E7 solid',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
  cursor: 'default',
}

// Memoized Dropdown Options Component
const DropdownOptions = React.memo(({ filter, setFilter }) => (
  <Dropdown.Menu style={{ padding: 20 }}>
    {DROPDOWN_OPTIONS.map(({ label, value, type, option }) => (
      <Dropdown.Item
        key={option}
        className="custom-dropdown-item"
        style={{ cursor: option === 3 ? 'default' : 'pointer' }}
      >
        <input
          className="drop-down-radio"
          type="radio"
          checked={filter?.option === option}
          onChange={() => setFilter({ value, type, option })}
        />
        {label}
      </Dropdown.Item>
    ))}
  </Dropdown.Menu>
))
DropdownOptions.displayName = 'DropdownOptions'

const FilteDropDownButton = React.memo(
  ({ leftImage, Text, filter, setFilter }) => {
    const datepickerRef = useRef(null)
    const [showDropdown, setShowDropdown] = useState(false)

    // Open date picker on icon click
    const handleDateIconClick = useCallback(() => {
      datepickerRef.current?.setOpen(true)
    }, [])

    // Toggle dropdown visibility
    const toggleDropdown = useCallback(
      (e) => {
        e.preventDefault()
        setShowDropdown((prev) => !prev)
      },
      [setShowDropdown],
    )

    // Map Text to appropriate label or date format
    const formattedText = useMemo(() => {
      const textMap = {
        30: 'Last 30 days',
        7: 'Last 7 days',
      }
      return (
        textMap[Text] ||
        DateTime.fromFormat(Text, 'dd/MM/yyyy').toFormat('MMMM d, yyyy')
      )
    }, [Text])

    return (
      <Dropdown
        show={showDropdown}
        onToggle={(isOpen) => setShowDropdown(isOpen)}
        className=""
      >
        <div style={containerStyle}>
          {/* Date Icon and Picker */}
          <div style={{ width: 24, height: 24 }}>
            <Image
              src={leftImage}
              style={{ cursor: 'pointer' }}
              onClick={handleDateIconClick}
            />
            <DatePicker
              selected={filter?.type === 'date' ? filter.value : null}
              onChange={(date) => setFilter({ type: 'date', value: date })}
              dateFormat="dd/MM/yyyy"
              ref={datepickerRef}
              customInput={<div />} // Hide default input
            />
          </div>

          {/* Display Selected Date/Range */}
          <div
            style={{
              color: '#383838',
              fontSize: 14,
              fontFamily: 'Inter',
              fontWeight: 400,
              wordWrap: 'break-word',
            }}
          >
            {formattedText}
          </div>

          {/* Dropdown Toggle */}
          <Dropdown.Toggle
            id="dropdown-basic"
            variant="outline-secondary"
            className="d-flex align-items-left health-date-dropdown"
            onClick={toggleDropdown}
            size="lg"
          />
        </div>

        {/* Render Memoized Dropdown Options */}
        <DropdownOptions filter={filter} setFilter={setFilter} />
      </Dropdown>
    )
  },
)

//  Set displayName to avoid ESLint warning
FilteDropDownButton.displayName = 'FilteDropDownButton'

export default FilteDropDownButton
