import React, { useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import {
  getUserNameFromCommunityArray,
  getUserName,
} from '../../../../core/utils/user.js'
import { getUserColor } from '../../../../core/utils/graphs'
import './ChartJsInit.js'

const generateRGBA = (hex, alpha = 0.2) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const AreaChart = ({
  data,
  userIdArray,
  communityUsers,
  currentUser,
  labelUnit,
}) => {
  // Generate datasets for each user
  const datasets = useMemo(
    () =>
      userIdArray.map((userId, index) => {
        const username =
          getUserNameFromCommunityArray(userId, communityUsers) ||
          getUserName(currentUser)

        const color = getUserColor(userId, index)

        return {
          label: username,
          data: data.map((item) => item[userId] || null),
          borderColor: color,
          backgroundColor: generateRGBA(color),
          fill: true,
          tension: 0.4,
          pointRadius: 0,
          borderWidth: 1,
          spanGaps: true,
        }
      }),
    [data, userIdArray, communityUsers, currentUser],
  )

  const chartData = useMemo(
    () => ({
      labels: data.map((item) => item.time),
      datasets,
    }),
    [data, datasets],
  )

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          backgroundColor: '#fff',
          titleColor: '#000',
          bodyColor: '#000',
          cornerRadius: 10,
          boxPadding: 5,
          borderColor: '#E8E7E7',
          borderWidth: 1,
          bodySpacing: 5,
          padding: 10,
          boxWidth: 8,
          boxHeight: 8,
          callbacks: {
            title: (tooltipItems) => {
              return `Time: ${tooltipItems[0].label}`
            },
            label: (tooltipItem) => {
              const datasetLabel = tooltipItem.dataset.label || 'User'
              const value =
                tooltipItem.raw !== null ? tooltipItem.raw : 'No data'
              return `${datasetLabel}: ${value}${labelUnit}`
            },
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'x',
          },
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              size: 14,
            },
          },
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            callback: (value) => `${value} ${labelUnit.toString().trim()}`, // Append unit to Y-axis values
          },
        },
      },
    }),
    [labelUnit],
  )

  return (
    <div style={{ width: '100%', height: '250px' }}>
      <Line data={chartData} options={options} />
    </div>
  )
}
