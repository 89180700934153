import { useContext } from 'react'
import { AuthContext } from '../../../hooks/AuthContext'
import { Card } from 'react-bootstrap'
import { AreaChart } from './ChartJs/AreaChart'
import { LineChart } from './ChartJs/LineChart'
import {
  formatAndSortTimeData,
  filterDataByType,
  transformAndGroupData,
} from '../../../core/utils'
import Average from './Average'

const HeartRateCard = ({
  timmingData,
  selectedUserIds,
  companyUsers,
  filter,
}) => {
  const { user: currentUser } = useContext(AuthContext)
  const heartRateData = filterDataByType(timmingData, 1, filter.type)
  const formatedHeartRateData = formatAndSortTimeData(
    heartRateData,
    filter.type,
  )

  const { groupedData, userIdArray } = transformAndGroupData(
    formatedHeartRateData,
    filter.type,
  )

  return (
    <Card className="healthCardContainer">
      <Card.Body>
        <Card.Title>
          <div className="healthCardTitle">Heart Rate</div>
          <Average
            selectedUserIds={selectedUserIds}
            companyUsers={companyUsers}
            groupedData={groupedData}
            type="range"
            filter={filter}
            cardType="heartRate"
          />
        </Card.Title>
        {heartRateData && heartRateData.length > 0 ? (
          filter.type === 'date' ? (
            <AreaChart
              data={groupedData}
              userIdArray={userIdArray}
              type={'hearthRate'}
              companyUsers={companyUsers}
              currentUser={currentUser}
            />
          ) : (
            <LineChart
              data={groupedData}
              userIdArray={userIdArray}
              type={'heartRateVariability'}
              companyUsers={companyUsers}
              currentUser={currentUser}
            />
          )
        ) : (
          <div className="noData">No data available for the selected date</div>
        )}
      </Card.Body>
    </Card>
  )
}

export default HeartRateCard
