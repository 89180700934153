import { useState } from 'react'

const useForm = (initialValues, validate = () => {}) => {
  const [formData, setFormData] = useState(initialValues)
  const [errors, setErrors] = useState({})
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }))
  }

  // set specific field
  const setField = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const validateFields = () => {
    const newErrors = validate(formData)
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  return {
    formData,
    setFormData,
    errors,
    setErrors,
    handleChange,
    validateFields,
    loading,
    setLoading,
    setField,
    message,
    setMessage,
  }
}

export default useForm
