export const scrollToSection = (ref) => {
  const section = document.querySelector(`#${ref}`)
  const menuBarHeight = document.querySelector('.menuBar').offsetHeight

  window.scrollTo({
    top: section.offsetTop - 50 - menuBarHeight,
    behavior: 'smooth',
  })
}

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0
}

// Helper to handle empty or undefined values
export const renderValue = (value) => (value ? value : '-')
export const capitalize = (str) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1) : ''
