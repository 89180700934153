import { useCallback, useMemo, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { getUserName } from '../../core/utils/user.js'

export const CompareUsersDialog = ({
  showCompareUsers,
  setShowCompareUsers,
  communityUsers,
  selectedUserIds,
  setSelectedUserIds,
}) => {
  // Local state to store temporary selections
  const [tempSelectedUserIds, setTempSelectedUserIds] =
    useState(selectedUserIds)

  // Memoize handleCheckboxChange to modify only tempSelectedUserIds
  const handleCheckboxChange = useCallback((id) => {
    setTempSelectedUserIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((userId) => userId !== id)
        : [...prevSelected, id],
    )
  }, [])

  // Memoize the rendered list to avoid unnecessary re-renders
  const renderUserList = useMemo(
    () =>
      communityUsers.map((user) => (
        <div className="user-list" key={user._id}>
          <div style={{ paddingBottom: 20 }}>
            <div className="user-list-element">
              <input
                type="checkbox"
                value={user._id}
                checked={tempSelectedUserIds.includes(user._id)}
                onChange={() => handleCheckboxChange(user._id)}
                style={{
                  border: '1px solid #D9D9D9 !important',
                  boxShadow: '0px 1px 1px 1px #D9D9D9',
                  height: 20,
                  width: 20,
                  top: 4,
                  position: 'relative',
                  marginRight: 6,
                }}
              />
              {getUserName(user)}
            </div>
          </div>
        </div>
      )),
    [communityUsers, tempSelectedUserIds, handleCheckboxChange],
  )

  // Apply selection only when button is clicked
  const handleSelectClick = useCallback(() => {
    // Update selectedUserIds only on button click
    setSelectedUserIds(tempSelectedUserIds)
    setShowCompareUsers(false)
  }, [tempSelectedUserIds, setSelectedUserIds, setShowCompareUsers])

  return (
    <>
      <Offcanvas
        show={showCompareUsers}
        onHide={() => setShowCompareUsers(false)}
        placement="end"
      >
        <Offcanvas.Body
          style={{
            padding: '90px 30px',
          }}
        >
          <div>
            <div className="user-list-header">User List</div>
            <div className="user-list-details">
              Select users to See Insights
            </div>
            {renderUserList}
          </div>
          <button
            className="user-list-compare-button"
            onClick={handleSelectClick}
          >
            Select
          </button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
