import { Form, Button, Container, Row, Col } from 'react-bootstrap'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { apiPost } from '../../../core/api.js'
import { useContext } from 'react'
import { AuthContext } from '../../../hooks/AuthContext'
import { useTranslation } from 'react-i18next'

const SignupForm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { login } = useContext(AuthContext)

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
  })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }))
  }
  const validateFields = () => {
    const newErrors = {}

    if (formData.firstname.length === 0) {
      newErrors.firstname = t('signup.validations.firstnameRequired')
    }

    if (formData.lastname.length === 0) {
      newErrors.lastname = t('signup.validations.lastnameRequired')
    }

    if (formData.email.length === 0) {
      newErrors.email = t('signup.validations.emailRequired')
    }
    if (formData.email.length > 0 && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t('signup.validations.emailInvalid')
    }
    if (formData.password.length === 0) {
      newErrors.password = t('signup.validations.passwordRequired')
    }
    if (formData.password.length > 0 && formData.password.length < 8) {
      newErrors.password = t('signup.validations.passwordMin')
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrors({}) // Reset errors before validation

    if (!validateFields()) return
    try {
      setLoading(true)
      const response = await apiPost('signup', {
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        password: formData.password,
      })
      login(response?.data?.data?.token)
      setLoading(false)
      navigate('/')
    } catch (error) {
      setLoading(false)
      setErrors({ api: error.response?.data?.message || t('errors.500') })
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {errors?.api && <p className="error-msg mt-3">{errors.api}</p>}
      <Container className="m-0 p-0">
        <Row>
          <Col className="m-0 p-0">
            <Form.Group controlId="formFirstname">
              <Form.Label className="txt-label">
                {t('signup.form.firstNameLabel')}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t('signup.form.firstNamePlaceholder')}
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                className={errors?.firstname ? 'error-txt-field ' : 'txt-field'}
              />
              {errors?.firstname && (
                <p className="error-msg">{errors.firstname}</p>
              )}
            </Form.Group>
          </Col>

          <Col className=" pe-0">
            <Form.Group controlId="formLastname">
              <Form.Label className="txt-label">
                {t('signup.form.lastNameLabel')}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t('signup.form.lastNamePlaceholder')}
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                className={errors?.lastname ? 'error-txt-field ' : 'txt-field'}
              />
              {errors?.lastname && (
                <p className="error-msg">{errors.lastname}</p>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <Form.Group controlId="formEmail">
        <Form.Label className="txt-label">
          {t('signup.form.emailLabel')}
        </Form.Label>
        <Form.Control
          type="email"
          placeholder={t('signup.form.emailPlaceholder')}
          name="email"
          value={formData.email}
          onChange={handleChange}
          className={errors?.email ? 'error-txt-field ' : 'txt-field'}
        />
        {errors?.email && <p className="error-msg">{errors.email}</p>}
      </Form.Group>

      <Form.Group controlId="formPassword">
        <Form.Label className="txt-label">
          {t('signup.form.passwordLabel')}
        </Form.Label>
        <Form.Control
          type="password"
          placeholder={t('signup.form.passwordPlaceholder')}
          name="password"
          value={formData.password}
          onChange={handleChange}
          className={errors?.password ? 'error-txt-field ' : 'txt-field'}
        />
        {errors?.password && <p className="error-msg">{errors.password}</p>}
      </Form.Group>

      <div className="div-submit">
        <Button className="submit-btn" type="submit">
          {loading ? t('btn.progress') : t('btn.continue')}
        </Button>
      </div>
    </Form>
  )
}

export default SignupForm
