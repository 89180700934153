import { useRef } from 'react'
import { Image } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const FilterButton = ({
  leftImage,
  Text,
  rightImage,
  handleButtonClick,
  isDate = false,
  filter,
  setFilter,
}) => {
  const datepickerRef = useRef(null)
  const handleDateIconClick = () => {
    datepickerRef.current.setOpen(true)
  }
  return (
    <div
      style={{
        paddingLeft: 14,
        paddingRight: 14,
        paddingTop: 10,
        paddingBottom: 10,
        background: 'white',
        boxShadow: '0px 1px 50px rgba(0, 0, 0, 0.02)',
        borderRadius: 12,
        border: '1px #E8E7E7 solid',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        display: 'inline-flex',
        cursor: handleButtonClick ? 'pointer' : 'default',
      }}
      onClick={handleButtonClick ? handleButtonClick : null}
    >
      {isDate ? (
        <div style={{ width: 24, height: 24 }}>
          <Image src={`${leftImage}`} onClick={handleDateIconClick} />
          <DatePicker
            selected={filter.type === 'date' ? filter.value : null}
            onChange={(date) => setFilter({ type: 'date', value: date })}
            dateFormat="dd/MM/yyyy"
            ref={datepickerRef}
            customInput={<div />} // Hide default input
          />
        </div>
      ) : (
        <div style={{ width: 24, height: 24 }}>
          <Image src={`${leftImage}`} />
        </div>
      )}
      <div
        style={{
          color: '#383838',
          fontSize: 14,
          fontFamily: 'Inter',
          fontWeight: '400',
          wordWrap: 'break-word',
        }}
        onClick={isDate ? handleDateIconClick : () => {}}
      >
        {Text}
      </div>
      {rightImage && (
        <div style={{ width: 24, height: 24 }}>
          <Image src={`${rightImage}`} />
        </div>
      )}
    </div>
  )
}
export default FilterButton
