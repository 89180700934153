import axiosInstance from './axiosInterceptor'

// Global function for GET requests
export const apiGet = async (url, config = {}) =>
  await axiosInstance.get(url, config)

// Global function for POST requests
export const apiPost = async (url, data, config = {}) =>
  axiosInstance.post(url, data, config)

// Global function for PUT requests
export const apiPut = async (url, data, config = {}) =>
  axiosInstance.put(url, data, config)

// Global function for DELETE requests
export const apiDelete = async (url, config = {}) =>
  axiosInstance.delete(url, config)
