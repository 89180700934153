import React, { memo } from 'react'
import HeartRateCard from './HeartRateCard.js'
import SleepDataCard from './SleepDataCard.js'
import SpO2Card from './SpO2Card.js'
import StepsCard from './StepsCard.js'
import TemperatureCard from './TemperatureCard.js'
import HeartRateVariabilityCard from './HeartRateVariabilityCard.js'

// Memoize individual cards to prevent unnecessary re-renders
const MemoizedHeartRateCard = memo(HeartRateCard)
const MemoizedHeartRateVariabilityCard = memo(HeartRateVariabilityCard)
const MemoizedSleepDataCard = memo(SleepDataCard)
const MemoizedSpO2Card = memo(SpO2Card)
const MemoizedTemperatureCard = memo(TemperatureCard)
const MemoizedStepsCard = memo(StepsCard)

export const getHealthSections = (
  healthData,
  selectedUserIds,
  communityUsers,
  filter,
) => {
  return [
    {
      id: 'heartDataRef',
      title: 'Heart Data',
      cards: [
        <MemoizedHeartRateCard
          data={healthData}
          key={'heartrate'}
          communityUsers={communityUsers}
          selectedUserIds={selectedUserIds}
          filter={filter}
        />,
        <MemoizedHeartRateVariabilityCard
          data={healthData}
          key={'hrv'}
          communityUsers={communityUsers}
          selectedUserIds={selectedUserIds}
          filter={filter}
        />,
      ],
    },
    {
      id: 'sleepDataRef',
      title: 'Sleep Data',
      cards: [
        <MemoizedSleepDataCard
          data={healthData}
          key={'sleep'}
          communityUsers={communityUsers}
          filter={filter}
        />,
      ],
    },
    {
      id: 'oxygenLevelsRef',
      title: 'Oxygen Levels',
      cards: [
        <MemoizedSpO2Card
          data={healthData}
          key={'oxygen'}
          communityUsers={communityUsers}
          selectedUserIds={selectedUserIds}
          filter={filter}
        />,
      ],
    },
    {
      id: 'bodyTempRef',
      title: 'Body Temperature',
      cards: [
        <MemoizedTemperatureCard
          data={healthData}
          key={'temperature'}
          communityUsers={communityUsers}
          selectedUserIds={selectedUserIds}
          filter={filter}
        />,
      ],
    },
    {
      id: 'stepsTakenRef',
      title: 'Steps Taken',
      cards: [
        <MemoizedStepsCard
          data={healthData}
          key={'steptaken'}
          communityUsers={communityUsers}
          selectedUserIds={selectedUserIds}
          filter={filter}
        />,
      ],
    },
  ]
}
