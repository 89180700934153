import ClientLogos from './ClientLogo'

const SignupWidgetRightBottom = () => {
  return (
    <div className="signup-widget-right-bottom">
      <p className="bottom-widjet-txt">
        100+ companies are using Yeyro enterprises wearables platform.
      </p>
      <ClientLogos />
    </div>
  )
}
export default SignupWidgetRightBottom
