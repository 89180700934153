import React, { useState, useEffect } from 'react'
import { apiPost } from '../core/api.js'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../hooks/AuthContext'
import TopBar from '../core/components/layouts/TopBar.jsx'
import SignupRightWidget from '../core/components/SignupRightBar/SignupRightWidget.jsx'
import { Form, Button, Container, Image, Row, Col } from 'react-bootstrap'

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
  })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const { login, token, redirectToGoogle } = useContext(AuthContext)
  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }))
  }
  const validateFields = () => {
    const newErrors = {}

    if (formData.firstname.length === 0) {
      newErrors.firstname = 'Firstname is Required'
    }

    if (formData.lastname.length === 0) {
      newErrors.lastname = 'Lastname is Required'
    }

    if (formData.email.length === 0) {
      newErrors.email = 'Email is Required'
    }
    if (formData.email.length > 0 && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email'
    }
    if (formData.password.length === 0) {
      newErrors.password = 'Password is Required'
    }
    if (formData.password.length > 0 && formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters long'
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrors({}) // Reset errors before validation

    if (!validateFields()) return
    try {
      setLoading(true)
      const response = await apiPost('signup', {
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        password: formData.password,
      })
      login(response?.data?.data?.token)
      setLoading(false)
      navigate('/')
    } catch (error) {
      setLoading(false)
      setErrors({ api: error.response?.data?.message || 'An error occurred' })
    }
  }

  useEffect(() => {
    if (token && token.length > 0) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <Container
      fluid
      className="signup-main"
      style={{ height: '100% !important' }}
    >
      <Row className="signup-row" style={{ height: '100%' }}>
        <Col md={6} style={{ padding: 0, marginRight: 0 }}>
          <TopBar className={'topnav'} />
          <div className="signup-box">
            <h1>Sign Up</h1>
            {errors?.api && <p className="error-msg mt-3">{errors.api}</p>}
            <Button
              className="google-btn text-center "
              onClick={() => redirectToGoogle()}
              style={{ marginTop: '20px' }}
            >
              <Image
                src="/images/google-btn.svg"
                width={18}
                height={18}
                alt="google"
              />
              <span className="google-btn-text">Sign up with Google</span>
            </Button>

            <div className="separator or-txt">OR</div>

            <Form onSubmit={handleSubmit}>
              <Container className="m-0 p-0">
                <Row>
                  <Col className="m-0 p-0">
                    <Form.Group controlId="formFirstname">
                      <Form.Label className="txt-label">First name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First name"
                        name="firstname"
                        value={formData.firstname}
                        onChange={handleChange}
                        className={
                          errors?.firstname ? 'error-txt-field ' : 'txt-field'
                        }
                      />
                      {errors?.firstname && (
                        <p className="error-msg">{errors.firstname}</p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col className=" pe-0">
                    <Form.Group controlId="formLastname">
                      <Form.Label className="txt-label">Last name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last name"
                        name="lastname"
                        value={formData.lastname}
                        onChange={handleChange}
                        className={
                          errors?.lastname ? 'error-txt-field ' : 'txt-field'
                        }
                      />
                      {errors?.lastname && (
                        <p className="error-msg">{errors.lastname}</p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Container>
              <Form.Group controlId="formEmail">
                <Form.Label className="txt-label">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={errors?.email ? 'error-txt-field ' : 'txt-field'}
                />
                {errors?.email && <p className="error-msg">{errors.email}</p>}
              </Form.Group>

              <Form.Group controlId="formPassword">
                <Form.Label className="txt-label">Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className={
                    errors?.password ? 'error-txt-field ' : 'txt-field'
                  }
                />
                {errors?.password && (
                  <p className="error-msg">{errors.password}</p>
                )}
              </Form.Group>

              <div className="div-submit">
                <Button className="submit-btn" type="submit">
                  {loading ? 'In Progress...' : 'Continue'}
                </Button>
              </div>
            </Form>
            <div className="div-policy-txt">
              By continuing, you agreed to our{' '}
              <a
                href="https://www.yeyroring.com/policies/terms-of-service"
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>
              , and
              <a
                href="https://www.yeyroring.com/policies/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                Privacy Policy
              </a>
            </div>
            <hr className="section-seprator" />
            <Row className="signInUp-footer-div">
              <Col className="text-left" md={8}>
                <div className="div-signin-txt">Already have an account?</div>
              </Col>
              <Col md={4}>
                <Button
                  className="signInUp-footer-div-btn float-end"
                  onClick={() => navigate('/signin')}
                >
                  Sign In
                </Button>
              </Col>
            </Row>
          </div>
        </Col>

        <Col
          md={6}
          style={{ padding: 0, background: '#fff', paddingBottom: '100px' }}
        >
          <SignupRightWidget />
        </Col>
      </Row>
    </Container>
  )
}

export default SignUp
