import { useContext, useMemo } from 'react'
import { AuthContext } from '../../../hooks/AuthContext'
import { Card } from 'react-bootstrap'
import { Line } from 'react-chartjs-2'
import './ChartJs/ChartJsInit.js'
import {
  filterSleepDataByType,
  stageMapping,
  getUserColor,
} from '../../../core/utils/graphs'

import {
  getUserNameFromCommunityArray,
  getUserName,
} from '../../../core/utils/user.js'

const SleepDataCard = ({ data, communityUsers, filter }) => {
  const { user: currentUser } = useContext(AuthContext)
  const sleepData = filterSleepDataByType(data, filter.type)
  // Memoize filtered sleep data
  const { chartData, userLines } = useMemo(() => {
    const dataMap = new Map()
    const linesMap = new Map()
    let prevValue = null

    sleepData?.forEach((data, index) => {
      if (data.stage === prevValue || data.stage == null) return
      prevValue = data.stage

      const sleepEntry = dataMap.get(data.orignalStartTime) || {
        time: data.time,
        orignalTime: data.orignalStartTime,
      }
      sleepEntry[data.userId] = data.stage
      dataMap.set(data.orignalStartTime, sleepEntry)

      if (!linesMap.has(data.userId)) {
        linesMap.set(data.userId, {
          userId: data.userId,
          color: getUserColor(data.userId, index),
        })
      }
    })

    const sortedData = Array.from(dataMap.values()).sort(
      (a, b) => new Date(a.orignalTime) - new Date(b.orignalTime),
    )

    return {
      chartData: sortedData,
      userLines: Array.from(linesMap.values()),
    }
  }, [sleepData])

  // Memoize datasets to avoid recalculating
  const datasets = useMemo(
    () =>
      userLines.map((user) => {
        const username =
          getUserNameFromCommunityArray(user.userId, communityUsers) ||
          getUserName(currentUser)

        const borderColor = user.color
        const backgroundColor = `rgba(${parseInt(borderColor.slice(1, 3), 16)}, 
          ${parseInt(borderColor.slice(3, 5), 16)}, 
          ${parseInt(borderColor.slice(5, 7), 16)}, 0.2)`

        return {
          id: user.userId,
          label: username,
          data: chartData.map((entry) => entry[user.userId] || null),
          borderColor,
          backgroundColor,
          fill: false,
          tension: 0,
          spanGaps: true,
          borderWidth: 2,
          pointBackgroundColor: '#fff',
          stepped: true,
        }
      }),
    [userLines, chartData, communityUsers, currentUser],
  )

  // Chart data and options
  const labeledData = {
    labels: chartData.map((entry) => entry.time),
    datasets,
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'bottom' },
      tooltip: {
        callbacks: {
          label: (context) => stageMapping[context.raw] || '',
          title: (context) => `Time: ${context[0]?.label}`,
        },
        backgroundColor: '#fff',
        titleColor: '#000',
        bodyColor: '#000',
        cornerRadius: 10,
        boxPadding: 5,
        borderColor: '#E8E7E7',
        borderWidth: 2,
        bodySpacing: 5,
        padding: 10,
        boxWidth: 8,
        boxHeight: 8,
      },
      zoom: {
        pan: { enabled: true, mode: 'x' },
        zoom: {
          wheel: { enabled: true },
          pinch: { enabled: true },
          mode: 'x',
        },
      },
    },
    scales: {
      x: {
        title: { display: false },
        ticks: { autoSkip: false },
        grid: { display: false, tickLength: 20 },
      },
      y: {
        title: { display: false },
        min: 0,
        max: 5,
        ticks: {
          callback: (value) => stageMapping[value] || '',
        },
      },
    },
  }

  return (
    <Card className="healthCardContainer">
      <Card.Body>
        <Card.Title className="healthCardTitle">Sleep Data Overview</Card.Title>
        {chartData.length > 0 ? (
          <div style={{ width: '100%', height: '250px' }}>
            <Line datasetIdKey="id" data={labeledData} options={options} />
          </div>
        ) : (
          <div className="noData">No sleep data available</div>
        )}
      </Card.Body>
    </Card>
  )
}

export default SleepDataCard
