import { useEffect } from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../hooks/AuthContext'
import TopBar from '../core/components/layouts/TopBar.jsx'
import SignupRightPanel from '../features/signup/SignupRightPanel/index.jsx'
import SignupLeftPanel from '../features/signup/SignupLeftPanel/index.jsx'
import { Container, Row, Col } from 'react-bootstrap'

const SignUp = () => {
  const { token } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (token && token.length > 0) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <Container
      fluid
      className="signup-main"
      style={{ height: '100% !important' }}
    >
      <Row className="signup-row" style={{ height: '100%' }}>
        <Col md={6} style={{ padding: 0, marginRight: 0 }}>
          <TopBar className={'topnav'} />
          <SignupLeftPanel />
        </Col>

        <Col
          md={6}
          style={{ padding: 0, background: '#fff', paddingBottom: '100px' }}
        >
          <SignupRightPanel />
        </Col>
      </Row>
    </Container>
  )
}

export default SignUp
