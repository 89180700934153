import { useEffect, useContext, useState, useCallback, useMemo } from 'react'
import { AuthContext } from '../../hooks/AuthContext.jsx'
import { fetchCommunityUsers } from '../Health/healthApi.js'
import Loader from '../../core/components/Loader.jsx'
import { capitalize, renderValue } from '../../core/utils/index.js'

const UserList = () => {
  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [communityUsers, setCommunityUsers] = useState([])

  const communityId = useMemo(() => user?.communities?.[0]?._id, [user])

  const loadCommunityUsers = useCallback(async () => {
    if (!user?._id || !communityId) return
    setLoading(true)
    try {
      const users = await fetchCommunityUsers(user._id, communityId)
      setCommunityUsers(users)
    } finally {
      setLoading(false)
    }
  }, [user?._id, communityId])

  useEffect(() => {
    loadCommunityUsers()
  }, [loadCommunityUsers])

  return (
    <div className="table-container">
      {loading ? (
        <Loader />
      ) : (
        <table className="table list-table table-responsive">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {communityUsers.map(({ _id, firstName, lastName, email }) => (
              <tr key={_id}>
                <td>{capitalize(renderValue(firstName))}</td>
                <td>{capitalize(renderValue(lastName))}</td>
                <td>{renderValue(email)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default UserList
