import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { Container, Image, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../../hooks/AuthContext'

const TopBar = ({ className = '', showLogout = false }) => {
  const { t } = useTranslation()
  const { user, logout } = useContext(AuthContext)

  return (
    <Container className={` ${className}`}>
      <Link to="/">
        <Image
          src="/images/logo.svg"
          height={68}
          width={68}
          className="d-inline-block align-top"
          alt={t('appName')}
        />
      </Link>
      {showLogout && user && (
        <Button
          className="app-btn float-end p-2 mt-3"
          as={Link}
          onClick={() => logout()}
        >
          {t('Sign out')}
        </Button>
      )}
    </Container>
  )
}

export default TopBar
