import React, { useState, useEffect } from 'react'
import { apiPost } from '../core/api.js'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../hooks/AuthContext'
import { Form, Button, Container, Image, Row, Col } from 'react-bootstrap'
import TopBar from '../core/components/layouts/TopBar'

const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const { login, token, redirectToGoogle } = useContext(AuthContext)
  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }))
  }

  const validateFields = () => {
    const newErrors = {}

    if (formData.email.length === 0) {
      newErrors.email = 'Email is Required'
    }

    if (formData.email.length > 0 && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email'
    }
    if (formData.password.length === 0) {
      newErrors.password = 'Password is required'
    }
    if (formData.password.length > 0 && formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters long'
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrors({}) // Reset errors before validation

    if (!validateFields()) return

    try {
      setLoading(true)
      const response = await apiPost('/signin', {
        email: formData.email,
        password: formData.password,
      })

      login(response?.data?.data?.token)
      setLoading(false)
      navigate('/')
    } catch (error) {
      setLoading(false)
      setErrors({ api: error.response?.data?.message || 'An error occurred' })
    }
  }

  useEffect(() => {
    if (token && token.length > 0) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <Container className="company-form-container mx-auto " fluid>
      <TopBar className={'topnav text-center '} />
      <div className="company-form mx-auto" style={{ marginTop: 80 }}>
        <h1 style={{ marginBottom: 16 }}>Sign in</h1>
        <p className="login-welcome-txt">
          Welcome back! please enter your details.
        </p>
        {errors.api && <p className="text-danger mt-3">{errors.api}</p>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={errors?.email ? 'error-txt-field ' : 'txt-field'}
            />
            {errors.email && <p className="error-msg">{errors.email}</p>}
          </Form.Group>

          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className={errors?.password ? 'error-txt-field ' : 'txt-field'}
            />
            {errors.password && <p className="error-msg">{errors.password}</p>}
          </Form.Group>
          <div style={{ marginTop: '20px' }}>
            <Button className="submit-btn" type="submit">
              {loading ? 'In Progress...' : 'Sign in'}
            </Button>
          </div>
        </Form>
        <Button
          className="google-btn text-center "
          onClick={() => redirectToGoogle()}
          style={{ marginTop: 20 }}
        >
          <Image
            src="/images/google-btn.svg"
            width={18}
            height={18}
            alt="google"
          />
          <span className="google-btn-text">Sign in with Google</span>
        </Button>
        <hr className="section-seprator" />
        <Row className="signInUp-footer-div">
          <Col className="text-left" md={8}>
            <div className="div-signin-txt">Don’t have an account?</div>
          </Col>
          <Col md={4}>
            <Button
              className="signInUp-footer-div-btn float-end"
              onClick={() => navigate('/signup')}
            >
              Sign Up
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default Login
