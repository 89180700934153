import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row, Container, Image } from 'react-bootstrap'
import UserIcon from '../UserIcon'
import FilterButton from '../../../features/Health/FilterButton'
import { AuthContext } from '../../../hooks/AuthContext'

const HealthTopBar = ({ title = 'Health Overview' }) => {
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()
  const date = new Date()
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })

  return (
    <Container fluid className="m-0 p-0">
      <Row className="m-0 p-0">
        <Col className="m-0 p-0" md={7}>
          <div className="healthOverview">{title}</div>
          <div className="healthOverviewDate">{formattedDate}</div>
        </Col>
        <Col
          className="m-0 p-0"
          md={5}
          style={{ display: 'flex', flexDirection: 'row-reverse' }}
        >
          <Row className="m-0 p-0" style={{ textAlign: 'left' }}>
            <Col className="m-0 p-0">
              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 10,
                  display: 'inline-flex',
                }}
              >
                <UserIcon username={user?.email} />
                <div style={{ width: 104, marginRight: 50 }}>
                  <div>
                    <div
                      style={{
                        color: 'black',
                        fontSize: 14,
                        fontFamily: 'Inter',
                        fontWeight: '400',
                      }}
                    >
                      Hi {user?.firstname}
                      <Image
                        src="/images/right-arrow.svg"
                        style={{ marginLeft: 10, cursor: 'pointer' }}
                        onClick={() => navigate('/profile')}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      color: '#6A6969',
                      fontSize: 12,
                      fontFamily: 'Inter',
                      fontWeight: '400',
                      width: '100%',
                    }}
                  >
                    Welcome to Yeyro
                  </div>
                </div>
              </div>
            </Col>
            <Col className="m-0 p-0">
              <FilterButton
                leftImage="/images/help.svg"
                Text="Help Support"
                handleButtonClick={() =>
                  window.open('https://www.yeyroring.com/pages/support')
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <div
        style={{
          width: '100%',
          height: '100%',
          border: '1px #F0EAE1 solid',
          marginTop: 50,
          marginBottom: 30,
        }}
      ></div>
    </Container>
  )
}

export default HealthTopBar
