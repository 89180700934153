import { Image } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import React from 'react'

const FilterButton = React.memo(
  ({ leftImage, Text, handleButtonClick, showPointer = false }) => {
    return (
      <div
        style={{
          paddingLeft: 14,
          paddingRight: 14,
          paddingTop: 10,
          paddingBottom: 10,
          background: 'white',
          boxShadow: '0px 1px 50px rgba(0, 0, 0, 0.02)',
          borderRadius: 12,
          border: '1px #E8E7E7 solid',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
          display: 'inline-flex',
          cursor: handleButtonClick || showPointer ? 'pointer' : 'default',
        }}
        onClick={handleButtonClick || undefined}
      >
        <div style={{ width: 24, height: 24 }}>
          <Image src={leftImage} />
        </div>

        <div
          style={{
            color: '#383838',
            fontSize: 14,
            fontFamily: 'Inter',
            fontWeight: '400',
            wordWrap: 'break-word',
          }}
        >
          {Text}
        </div>
      </div>
    )
  },
)

// Set displayName to avoid ESLint warning
FilterButton.displayName = 'FilterButton'

export default FilterButton
