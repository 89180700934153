import { Stack } from 'react-bootstrap'
import UserIcon from '../../core/components/UserIcon'
import { useContext } from 'react'
import { AuthContext } from '../../hooks/AuthContext'
import { getUserName } from '../../core/utils'

const ProfileView = () => {
  const { user } = useContext(AuthContext)
  let username = getUserName(user)

  return (
    <div className="mx-auto profile-container">
      <Stack gap={3}>
        <div className="">
          <UserIcon username={username} size={80} backgroundcolor={'#F8DEBD'} />
        </div>
        <div className="profile-name">
          {user?.firstname && user?.lastname
            ? user?.firstname + ' ' + user?.lastname
            : '-'}
          <div className="profile-email">{user?.email}</div>
        </div>
        <div className="profile-header">Personal details</div>

        <div className="profile-field-title">Name</div>
        <div className="profile-field-value profile-field-value-border">
          {user?.firstname && user?.lastname
            ? user?.firstname + ' ' + user?.lastname
            : '-'}
        </div>
        <div className="profile-field-title">Email address</div>
        <div className="profile-field-value profile-field-value-border">
          {user?.email ? user?.email : '-'}
        </div>
        <div className="profile-field-title">Enterprise Name</div>
        <div className="profile-field-value profile-field-value-border">
          {user?.company_name ? user?.company_name : '-'}
        </div>
        <div className="profile-field-title">Mobile number</div>
        <div className="profile-field-value ">
          {user?.phonenumber ? user?.phonenumber : '-'}
        </div>
      </Stack>
    </div>
  )
}
export default ProfileView
