import { Container } from 'react-bootstrap'
import HealthTopBar from '../core/components/layouts/HealthTopBar'
import ComingSoon from '../features/ComingSoon'

const Users = () => {
  return (
    <Container
      fluid
      className="m-0"
      style={{
        background: '#FFFCF8',
        height: '100vh',
        padding: '50px',
      }}
    >
      <HealthTopBar title="Devices" />
      <div style={{ height: '20%' }} />
      <ComingSoon />
    </Container>
  )
}
export default Users
