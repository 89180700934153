import { useContext, useEffect, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { apiPost } from '../../core/api.js'
import { Form, Button } from 'react-bootstrap'
import useForm from '../../core/components/common/hooks/useForm'
import FormInput from '../../core/components/common/form/FormInput'
import FormSelect from '../../core/components/common/form/FormSelect'
import { AuthContext } from '../../hooks/AuthContext'
import { AppStateContext } from '../../hooks/AppStateContext.js'

const CommunityForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user, loadCurrentUser } = useContext(AuthContext)
  const { getAuthFlow } = useContext(AppStateContext)

  // Memoize validate function to avoid re-creating on every render
  const validate = useCallback(
    (values) => {
      const errors = {}
      if (!values.name) {
        errors.name = t('validations.required')
      }
      if (!values.industry) {
        errors.industry = t('validations.required')
      }
      return errors
    },
    [t],
  )

  const {
    formData,
    errors,
    handleChange,
    validateFields,
    loading,
    setLoading,
    setErrors,
  } = useForm({ name: '', industry: '' }, validate)

  // Memoize industry options to prevent re-creation
  const industryOptions = useMemo(
    () => [
      { value: '', label: t('community.form.industryPlaceholder') },
      { value: 'Healthcare', label: 'Healthcare' },
      { value: 'Transportation', label: 'Transportation' },
      { value: 'Construction', label: 'Construction' },
      { value: 'Mining', label: 'Mining' },
      { value: 'Software', label: 'Software' },
      { value: 'Employee Wellbeing', label: 'Employee Wellbeing' },
      { value: 'Insurance', label: 'Insurance' },
      { value: 'Others', label: 'Others' },
    ],
    [t],
  )

  // Memoize form submission to avoid unnecessary re-creation
  const addUserToCommunity = useCallback(async () => {
    try {
      setLoading(true)
      await apiPost('/v2/community/create-community-and-add-user', {
        ...formData,
        userId: user?._id,
      })
      // Load user again to include community info
      await loadCurrentUser()
      navigate('/')
    } catch (error) {
      setErrors({
        api: error?.response?.data?.data?.message || t('errors.500'),
      })
    } finally {
      setLoading(false)
    }
  }, [formData, user?._id, loadCurrentUser, navigate, setErrors, setLoading, t])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      if (!validateFields()) return
      await addUserToCommunity()
    },
    [validateFields, addUserToCommunity],
  )

  // Redirect to home if the user already has a community
  useEffect(() => {
    if (user?.communities?.length > 0) {
      navigate('/')
    }
    // Check if the user is in the sign-in flow,
    // then he hasn't created a community yet, show a error message

    const authFlow = getAuthFlow()
    if (authFlow && authFlow == 'signIn') {
      setErrors({
        api: t(
          "You haven't created any community yet. Please add a community to proceed.",
        ),
      })
    }
    // eslint-disable-next-line
  }, [user, navigate])

  return (
    <div className="community-form mx-auto">
      <h1 style={{ marginBottom: 16 }}>{t('community.setupEnterprise')}</h1>
      {errors.api && <p className="text-danger mt-3">{errors.api}</p>}
      <Form onSubmit={handleSubmit}>
        <FormInput
          label={t('community.form.communityField')}
          name="name"
          placeholder={t('community.form.communityField')}
          value={formData.name}
          onChange={handleChange}
          error={errors?.name}
          style={{ marginBottom: '0px' }}
        />
        <FormSelect
          label={t('community.form.industryField')}
          name="industry"
          value={formData.industry}
          onChange={handleChange}
          error={errors?.industry}
          options={industryOptions}
        />
        <div style={{ marginTop: '20px' }}>
          <Button className="submit-btn" type="submit" disabled={loading}>
            {loading ? t('btn.progress') : t('btn.continue')}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default CommunityForm
