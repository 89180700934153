import HeartRateCard from './HeartRateCard.js'
import SleepDataCard from './SleepDataCard.js'
import SpO2Card from './SpO2Card.js'
import StepsCard from './StepsCard.js'
import TemperatureCard from './TemperatureCard.js'
import HeartRateVariabilityCard from './HeartRateVariabilityCard.js'

export const getHealtSections = (
  healthData,
  selectedUserIds,
  companyUsers,
  filter,
) => {
  return [
    {
      id: 'heartDataRef',
      title: 'Heart Data',
      cards: [
        <HeartRateCard
          timmingData={healthData?.timmingData}
          key={'heartrate'}
          companyUsers={companyUsers}
          selectedUserIds={selectedUserIds}
          filter={filter}
        />,
        <HeartRateVariabilityCard
          hrvData={healthData?.hrvData}
          key={'hrv'}
          companyUsers={companyUsers}
          selectedUserIds={selectedUserIds}
          filter={filter}
        />,
      ],
    },
    {
      id: 'sleepDataRef',
      title: 'Sleep Data',
      cards: [
        <SleepDataCard
          sleepData={healthData?.sleepData}
          key={'sleep'}
          companyUsers={companyUsers}
          filter={filter}
        />,
      ],
    },
    {
      id: 'oxygenLevelsRef',
      title: 'Oxygen Levels',
      cards: [
        <SpO2Card
          timmingData={healthData?.timmingData}
          key={'oxygen'}
          companyUsers={companyUsers}
          selectedUserIds={selectedUserIds}
          filter={filter}
        />,
      ],
    },
    {
      id: 'bodyTempRef',
      title: 'Body Temperature',
      cards: [
        <TemperatureCard
          timmingData={healthData?.timmingData}
          key={'temprature'}
          companyUsers={companyUsers}
          selectedUserIds={selectedUserIds}
          filter={filter}
        />,
      ],
    },
    {
      id: 'stepsTakenRef',
      title: 'Steps Taken',
      cards: [
        <StepsCard
          timmingData={healthData?.timmingData}
          key={'steptaken'}
          companyUsers={companyUsers}
          selectedUserIds={selectedUserIds}
          filter={filter}
        />,
      ],
    },
  ]
}
