export const getGroupUserMaxAndMin = (data, userId, cardType, filter) => {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return { max: 0, min: 0 }
  }

  let min = 0
  let max = 0

  data.forEach((element) => {
    const value =
      filter.type === 'date'
        ? element[userId]
        : cardType === 'steps'
          ? element[userId]?.total_value
          : element[userId]?.min_value
    if (element[userId] && !min) min = value
    if (element[userId] && !max) max = value

    if (value && parseInt(value) < min) {
      min = value
    }
    if (value && parseInt(value) > max) {
      max = value
    }
  })

  return { min, max }
}
export const getGroupUserTotal = (data, userId, cardType, filter) => {
  const filteredData = data.filter((entry) => entry[userId] !== undefined)

  const values = filteredData.map((element) => {
    const value =
      filter.type === 'date'
        ? element[userId]
        : cardType === 'steps'
          ? element[userId]?.total_value
          : element[userId]?.min_value

    return Number(value)
  })
  const sum =
    values && values.length > 0
      ? values.reduce((acc, value) => acc + value, 0)
      : 0
  return sum
}
export const getGroupUserAverage = (data, userId, cardType, filter) => {
  const filteredData = data.filter((entry) => entry[userId] !== undefined)
  const values = filteredData.map((element) => {
    const value =
      filter.type === 'date'
        ? element[userId]
        : cardType === 'steps'
          ? element[userId]?.total_value
          : element[userId]?.min_value

    return Number(value)
  })
  const sum =
    values && values.length > 0
      ? values.reduce((acc, value) => acc + value, 0)
      : 0

  return values && values.length > 0 ? Math.floor(sum / values.length) : 0
}
