import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

const ComingSoon = () => {
  return (
    <Container fluid className="text-center">
      <div className="d-flex align-items-center justify-content-center h-100">
        <Row>
          <Col>
            <h1>Coming Soon</h1>
            <p>
              We are working hard to bring you something amazing. Stay tuned!
            </p>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default ComingSoon
