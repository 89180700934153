import { useState, useCallback, useMemo } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { scrollToSection } from '../../core/utils/index.js'

const HealthTab = ({
  heartDataRef,
  sleepDataRef,
  oxygenLevelsRef,
  bodyTempRef,
  stepsTakenRef,
}) => {
  const [selectedTab, setSelectedTab] = useState('Heart Data')

  // Memoized tabs array
  const tabs = useMemo(
    () => [
      'Heart Data',
      'Sleep Data',
      'Oxygen Levels',
      'Body Temperature',
      'Steps Taken',
    ],
    [],
  )

  // Ref mapping for tab names
  const tabRefMap = useMemo(
    () => ({
      'Heart Data': heartDataRef,
      'Sleep Data': sleepDataRef,
      'Oxygen Levels': oxygenLevelsRef,
      'Body Temperature': bodyTempRef,
      'Steps Taken': stepsTakenRef,
    }),
    [heartDataRef, sleepDataRef, oxygenLevelsRef, bodyTempRef, stepsTakenRef],
  )

  // Memoized getRef function
  const getRef = useCallback((tab) => tabRefMap[tab], [tabRefMap])

  // Memoized setActive function
  const setActive = useCallback((active, sectionRef) => {
    setSelectedTab(active)
    scrollToSection(sectionRef)
  }, [])

  return (
    <Container fluid className="p-0">
      <Row className="m-0 p-0">
        <Col
          className="m-0 p-0"
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 30,
          }}
        >
          {tabs.map((tab, index) => {
            const reference = getRef(tab)
            return (
              <div
                key={index}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 10,
                  display: 'inline-flex',
                  cursor: 'pointer',
                  paddingBottom: 16,
                }}
                className={`tab ${selectedTab === tab ? 'tab-selected' : ''}`}
                onClick={() => setActive(tab, reference)}
              >
                {tab}
              </div>
            )
          })}
        </Col>
      </Row>
    </Container>
  )
}

export default HealthTab
