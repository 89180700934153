import { Row, Col, Container, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ProfileView from '../features/users/ProfileView'

const Profile = () => {
  const { t } = useTranslation()
  const date = new Date()
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
  return (
    <Container
      className="p-0"
      fluid
      style={{ background: 'rgb(255, 252, 248)', height: '100vh' }}
    >
      <Container className={`profile-top-nav fluid`} fluid>
        <Row>
          <Col md={1}>
            <Link to="/">
              <Image
                src="/images/logo.svg"
                height={68}
                width={68}
                className="d-inline-block align-top"
                alt={t('appName')}
              />
            </Link>
          </Col>
          <Col md={11}>
            <div
              style={{ paddingBottom: 40, borderBottom: '1px solid #F0EAE1' }}
            >
              <div>
                <h1>Profile Details</h1>
              </div>
              <div className="profile-date">{formattedDate}</div>
              <ProfileView />
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Profile
