import { useEffect, useState, useContext, useCallback } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import HealthTopBar from '../../core/components/layouts/HealthTopBar.jsx'
import HealthFilters from './HealthFilters.jsx'
import HealthTab from './HealthTab.jsx'
import { apiGet } from '../../core/api.js'
import Loader from '../../core/components/Loader'
import { AuthContext } from '../../hooks/AuthContext'
import { CompareUsersDialog } from './CompareUsersDialog.jsx'
import { getHealtSections } from './Cards/health-section.js'

const HealthDashboard = () => {
  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [companyUsers, setCompanyUsers] = useState([])
  const [selectedUserIds, setSelectedUserIds] = useState([])
  const [healthData, setHealthData] = useState([])
  const [filter, setFilter] = useState({ value: new Date(), type: 'date' })

  const [showCompareUsers, setShowCompareUsers] = useState(false)

  const loadCompanyUsers = useCallback(async (user) => {
    setLoading(true)
    apiGet(`user/company/users`)
      .then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          // exclude current user
          const filteredUsers = res.data.data.filter(
            (companyUser) => companyUser.user_id !== user.user_id,
          )
          setCompanyUsers(filteredUsers)
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false) // Always stop loading after attempt
      })
  }, [])

  useEffect(() => {
    loadCompanyUsers(user)
  }, [user, loadCompanyUsers])

  const loadHealthData = useCallback(
    async (user, filter, selectedUserIds = []) => {
      let userIds = user.user_id
      if (selectedUserIds.length > 0) {
        userIds = `${userIds},${selectedUserIds.join(',')}`
      }

      const value =
        filter.type === 'date'
          ? filter.value.toLocaleDateString()
          : filter.value
      const type = filter.type

      setLoading(true)
      apiGet(
        `health/filter?userId=${userIds}&filterType=${type}&filterValue=${value}`,
      )
        .then((res) => {
          setHealthData(res.data.data)
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false) // Always stop loading after attempt
        })
    },
    [],
  )

  useEffect(() => {
    if (filter && user && user.user_id && user.user_id.length > 0) {
      loadHealthData(user, filter, selectedUserIds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, user, loadHealthData])

  if (loading) {
    return <Loader />
  }

  const healthSections = getHealtSections(
    healthData,
    selectedUserIds,
    companyUsers,
    filter,
  )
  return (
    <Container
      fluid
      className="m-0 p-0"
      style={{
        background: '#FFFCF8',
      }}
    >
      <Container
        fluid
        className="m-0  menuBar"
        fixed="top"
        style={{
          top: 0,
          width: 'calc(100% - 90px)',
          position: 'fixed',
          background: '#FFFCF8',
          zIndex: '1000',
          padding: '50px',
          paddingBottom: 0,
        }}
      >
        <HealthTopBar />
        <HealthFilters
          filter={filter}
          setFilter={setFilter}
          setShowCompareUsers={setShowCompareUsers}
          companyUsers={companyUsers}
          selectedUserIds={selectedUserIds}
        />
        <HealthTab
          heartDataRef={'heartDataRef'}
          sleepDataRef={'sleepDataRef'}
          oxygenLevelsRef={'oxygenLevelsRef'}
          bodyTempRef={'bodyTempRef'}
          stepsTakenRef={'stepsTakenRef'}
        />
        <div
          style={{
            width: '100%',
            height: '100%',
            border: '1px #F0EAE1 solid',
          }}
        ></div>
      </Container>
      <Container
        fluid
        className="m-0 staticContent"
        style={{
          padding: '50px',
          paddingTop: 370,
        }}
      >
        {healthSections.map(({ id, title, cards }) => (
          <Row key={id} style={{ marginBottom: 50 }} id={id}>
            <Col md={12} style={{ marginBottom: 30 }}>
              <span className="heathCardHeading" id={id}>
                {title}
                <Image
                  src="/images/arrow-down.svg"
                  className="ms-2"
                  style={{ cursor: 'pointer' }}
                />
              </span>
            </Col>
            {cards.map((CardComponent, index) => (
              <Col key={index} md={cards.length === 2 ? '6' : '12'}>
                {CardComponent}
              </Col>
            ))}
          </Row>
        ))}
        {/* Empty Div to add space at the bottom of the page for tab scroll to Step Taken */}
        <Row style={{ marginBottom: 220 }}>
          <Col>
            <CompareUsersDialog
              showCompareUsers={showCompareUsers}
              setShowCompareUsers={setShowCompareUsers}
              companyUsers={companyUsers}
              selectedUserIds={selectedUserIds}
              setSelectedUserIds={setSelectedUserIds}
              filter={filter}
              user={user}
              loadHealthData={loadHealthData}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default HealthDashboard
