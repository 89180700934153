import { useTranslation } from 'react-i18next'
import ClientLogos from './ClientLogo'

const SignupWidgetRightBottom = () => {
  const { t } = useTranslation()
  return (
    <div className="signup-widget-right-bottom">
      <p className="bottom-widjet-txt">{t('signup.rightPanel.clientText')}</p>
      <ClientLogos />
    </div>
  )
}
export default SignupWidgetRightBottom
