import { useTranslation } from 'react-i18next'
import { Button, Image } from 'react-bootstrap'

const DashboardMessage = () => {
  const { t } = useTranslation()

  return (
    <>
      <div
        className="text-center mx-auto"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 95,
          maxWidth: 660,
        }}
      >
        <Image
          src="/images/ring.svg"
          width={68}
          height={68}
          alt={t('dashboard.ringAltText')}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p className="dashboard-text">{t('dashboard.textHeaderThrilled')}</p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p className="dashboard-subtext">{t('dashboard.textFirstStep')}</p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p className="dashboard-subtext">{t('dashboard.textBuyRing')}</p>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
        }}
      >
        <Button
          className="submit-btn"
          type="submit"
          style={{ maxWidth: 350 }}
          onClick={() =>
            window.open(
              'https://www.yeyroring.com/?selectedProduct=7867677671562',
            )
          }
        >
          {t('dashboard.buttonShopNow')}
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '200px',
        }}
      >
        <p className="dashboard-footer">
          {t('dashboard.footerEmailMessage')}
          <a href="mailto:rav@yeyro.com">rav@yeyro.com</a>
        </p>
      </div>
    </>
  )
}

export default DashboardMessage
