import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'

const PopoverComponent = ({ children, content }) => {
  const popover = (
    <Popover id="popover-basic" className="sidebar-menu-tooltip">
      <Popover.Body>{content}</Popover.Body>
    </Popover>
  )

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="right"
      overlay={popover}
      delay={{ show: 250, hide: 200 }}
      popperConfig={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 15],
            },
          },
        ],
      }}
    >
      {children}
    </OverlayTrigger>
  )
}

export default PopoverComponent
