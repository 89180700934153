import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'

const ClientLogos = () => {
  return (
    <Container>
      <Row
        className="mx-auto"
        style={{ marginBottom: 40, width: 'fit-content' }}
      >
        <Col
          md={3}
          style={{ padding: 0, margin: '0 50px 0 0', width: 188, height: 27 }}
        >
          <Image
            src="images/clients/fulton.svg"
            alt="Fulton Hogan"
            style={{ width: 188, height: 27 }}
          />
        </Col>
        <Col
          md={3}
          style={{ padding: 0, margin: '0 50px 0 0', width: 50, height: 28 }}
        >
          <Image
            src="images/clients/loop.svg"
            alt="Loop"
            style={{ width: 50, height: 28 }}
          />
        </Col>
        <Col md={3} style={{ padding: 0, margin: '0', width: 117, height: 27 }}>
          <Image
            src="images/clients/Holcim.svg"
            alt="Holcim"
            style={{ width: 117, height: 27 }}
          />
        </Col>{' '}
      </Row>
      <Row
        style={{ marginBottom: 40, width: 'fit-content' }}
        className="mx-auto"
      >
        <Col
          md={3}
          style={{ padding: 0, margin: '0 50px 0 0', width: 112, height: 23 }}
        >
          <Image
            src="images/clients/Cipla.svg"
            alt="Cipla Health"
            style={{ width: 112, height: 23 }}
          />
        </Col>
        <Col
          md={3}
          style={{ padding: 0, margin: '0 50px 0 0', width: 53, height: 23 }}
        >
          <Image
            src="images/clients/GIVA.svg"
            alt="GIVA"
            style={{ width: 53, height: 23 }}
          />
        </Col>
        <Col md={3} style={{ padding: 0, margin: '0', width: 119, height: 24 }}>
          <Image
            src="images/clients/Neru.svg"
            alt="Neru Health"
            style={{ width: 119, height: 24 }}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default ClientLogos
