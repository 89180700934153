import React from 'react'
import { useTranslation } from 'react-i18next'
import { apiPost } from '../../core/api.js'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../hooks/AuthContext'
import { AppStateContext } from '../../hooks/AppStateContext.js'
import { Form, Button, Image, Row, Col } from 'react-bootstrap'
import FormInput from '../../core/components/common/form/FormInput.jsx'
import useForm from '../../core/components/common/hooks/useForm'

const LoginForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { redirectToGoogle } = useContext(AuthContext)
  const { updateAuthFlow } = useContext(AppStateContext)
  const validate = (data) => {
    const validationErrors = {}

    if (!data.email)
      validationErrors.email = t('signup.validations.emailRequired')
    if (data.email && !/\S+@\S+\.\S+/.test(data.email))
      validationErrors.email = t('signup.validations.emailInvalid')
    return validationErrors
  }

  const {
    formData,
    errors,
    handleChange,
    validateFields,
    loading,
    setLoading,
    setErrors,
  } = useForm({ firstName: '', lastName: '', email: '' }, validate)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrors({}) // Reset errors before validation

    if (!validateFields()) return

    try {
      setLoading(true)
      await apiPost('/v2/auth/web-signin', {
        email: formData.email,
      })

      updateAuthFlow('signIn')
      navigate(`/verification?email=${formData.email}`)
    } catch (error) {
      setLoading(false)
      setErrors({ api: error.response?.data?.data?.message || t('errors.500') })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="community-form mx-auto" style={{ marginTop: 80 }}>
        <h1 style={{ marginBottom: 16 }}>{t('login.loginHeader')}</h1>
        <p className="login-welcome-txt">{t('login.welcomeMessage')}</p>
        {errors.api && <p className="text-danger mt-3">{errors.api}</p>}
        <Form onSubmit={handleSubmit}>
          <FormInput
            label={t('signup.form.emailLabel')}
            name="email"
            type="email"
            placeholder={t('signup.form.emailPlaceholder')}
            value={formData.email}
            onChange={handleChange}
            error={errors.email}
          />
          <div style={{ marginTop: '20px' }}>
            <Button className="submit-btn" type="submit">
              {loading ? t('btn.progress') : t('login.form.btnSignIn')}
            </Button>
          </div>
        </Form>
        <Button
          className="google-btn text-center "
          onClick={() => redirectToGoogle()}
          style={{ marginTop: 20 }}
        >
          <Image
            src="/images/google-btn.svg"
            width={18}
            height={18}
            alt={t('login.btn.google')}
          />
          <span className="google-btn-text">{t('login.btn.google')}</span>
        </Button>
        <hr className="section-seprator" />
        <Row className="signInUp-footer-div">
          <Col className="text-left" md={8}>
            <div className="div-signin-txt">{t('login.textNoAccount')}</div>
          </Col>
          <Col md={4}>
            <Button
              className="signInUp-footer-div-btn float-end"
              onClick={() => navigate('/signup')}
            >
              {t('login.btn.signup')}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default LoginForm
