import { Container } from 'react-bootstrap'
import CompanyForm from '../features/company/CompanyForm'
import TopBar from '../core/components/layouts/TopBar'
import Stepper from '../core/components/Stepper'

const Company = () => (
  <Container className="company-form-container mx-auto " fluid>
    <TopBar className={'topnav text-center '} />
    <div
      className="text-center mx-auto"
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: 80,
        marginBottom: 80,
      }}
    >
      <Stepper currentStep={1} />
    </div>
    <CompanyForm />
  </Container>
)

export default Company
