import { useContext } from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap'
import FilterButton from './FilterButton'
import FilteDropDownButton from './FilteDropDownButton'
import { AuthContext } from '../../hooks/AuthContext'

const HealthFilters = ({
  filter,
  setFilter,
  setShowCompareUsers,
  companyUsers,
  selectedUserIds,
}) => {
  const { user } = useContext(AuthContext)

  return (
    <Container fluid className="p-0" style={{ marginBottom: 40 }}>
      <Row className="m-0 p-0">
        <Col className="m-0 p-0" md={6}>
          <span
            style={{
              color: '#303030',
              fontSize: 16,
              fontFamily: 'Inter',
              fontWeight: '600',
            }}
          >
            Health Record of{' '}
          </span>
          <span
            style={{
              color: '#4280EF',
              fontSize: 16,
              fontFamily: 'Inter',
              fontWeight: '600',
            }}
          >
            {user?.firstname}
          </span>

          {selectedUserIds.length > 0 && (
            <>
              <Image src="/images/arrow-down.svg" style={{ marginLeft: 10 }} />
              <span
                style={{
                  color: '#7B7B7B',
                  fontSize: 16,
                  fontFamily: 'Inter',
                  fontWeight: '500',
                  wordWrap: 'break-word',
                  marginLeft: 30,
                }}
              >
                And
              </span>

              <span
                key={`selected-user`}
                style={{
                  paddingLeft: 14,
                  paddingRight: 14,
                  paddingTop: 10,
                  paddingBottom: 10,
                  background: 'white',
                  boxShadow: '0px 1px 50px rgba(0, 0, 0, 0.02)',
                  borderRadius: 29,
                  border: '1px #E8E7E7 solid',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 20,
                  display: 'inline-flex',
                }}
              >
                <span
                  style={{
                    color: '#383838',
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontWeight: '400',
                  }}
                >
                  {
                    companyUsers.find(
                      (user) => user.user_id === selectedUserIds[0],
                    )?.firstname
                  }
                  {selectedUserIds.length > 1 && (
                    <span
                      style={{ fontWeight: 700, cursor: 'pointer' }}
                      onClick={() => setShowCompareUsers(true)}
                    >
                      {' '}
                      +{selectedUserIds.length - 1}
                    </span>
                  )}
                </span>
              </span>
            </>
          )}
        </Col>
        <Col
          className="m-0 p-0"
          md={6}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            gap: 30,
          }}
        >
          <FilterButton
            leftImage="/images/compare.svg"
            Text="Select Users"
            handleButtonClick={setShowCompareUsers}
          />
          {/* <FilterButton leftImage="/images/export.svg" Text="Export" /> */}

          <FilteDropDownButton
            leftImage="/images/date.svg"
            Text={
              filter.type === 'date'
                ? filter.value.toLocaleDateString()
                : filter.value
            }
            isDate={true}
            filter={filter}
            setFilter={setFilter}
          />
        </Col>
      </Row>
    </Container>
  )
}
export default HealthFilters
