import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { AuthContext } from '../../../hooks/AuthContext'
import { Button, Image, Row, Col } from 'react-bootstrap'
import SignupForm from './SignUpForm'

const SignupLeftPanel = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { redirectToGoogle } = useContext(AuthContext)

  return (
    <div className="signup-box">
      <h1>{t('signup.signupHeader')}</h1>

      <Button
        className="google-btn text-center "
        onClick={() => redirectToGoogle()}
        style={{ marginTop: '20px' }}
      >
        <Image
          src="/images/google-btn.svg"
          width={18}
          height={18}
          alt={t('signup.btn.google')}
        />
        <span className="google-btn-text">{t('signup.btn.google')}</span>
      </Button>

      <div className="separator or-txt">OR</div>
      <SignupForm />
      <div className="div-policy-txt">
        <Trans i18nKey="agreement">
          By continuing, you agreed to our{' '}
          <a
            href="https://www.yeyroring.com/policies/terms-of-service"
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions
          </a>
          , and{' '}
          <a
            href="https://www.yeyroring.com/policies/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </Trans>
      </div>
      <hr className="section-seprator" />
      <Row className="signInUp-footer-div">
        <Col className="text-left" md={8}>
          <div className="div-signin-txt">
            {t('signup.txtAlreadyHaveAccount')}
          </div>
        </Col>
        <Col md={4}>
          <Button
            className="signInUp-footer-div-btn float-end"
            onClick={() => navigate('/signin')}
          >
            {t('signup.btn.signin')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}
export default SignupLeftPanel
