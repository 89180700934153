import { DateTime } from 'luxon'

const formateDate = (datetime, format) => {
  return DateTime.fromISO(datetime, { zone: 'utc' }) // Parse as UTC
    .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone) // Detect user's time zone
    .toFormat(format)
}

export const stageMapping = {
  1: 'Deep',
  2: 'Light',
  3: 'REM',
  4: 'Awake',
}

export const getSleepStageByInterval = (stage, interval) => {
  if (interval > 1) {
    switch (stage) {
      case stage >= 0 && stage <= 2:
        return 1
      case stage > 2 && stage <= 8:
        return 2
      case stage > 8 && stage <= 20:
        return 3
      default:
        return 4
    }
  } else {
    return stage > 0 && stage <= 4 ? stage : 4
  }
}

const commonUserColors = [
  '#F3A53F',
  '#9878FF',
  '#1F77B4',
  '#FF7F0E',
  '#2CA02C',
  '#D62728',
  '#9467BD',
  '#4CAF50',
  '#FF5733',
  '#E91E63',
  '#1F77B4',
  '#D62728',
  '#9878FF',
]
export const getUserColor = (userId, index) => {
  // just matching design here
  if (index === 0) return commonUserColors[0]
  if (index === 1) return commonUserColors[1]
  const hash = userId
    .split('')
    .reduce((acc, char) => acc + char.charCodeAt(0), 0)
  return commonUserColors[hash % commonUserColors.length] // Always maps the same userId to the same color
}

export const filterDataByType = (
  data,
  type,
  typeKey,
  filterType,
  unit = 'C',
) => {
  if (!data || data.length === 0) {
    return []
  }

  const groupedData = new Map() // Map to store grouped min/max values

  const filteredByTpe = data?.filter((item) => item.type === type)

  filteredByTpe.forEach((item) => {
    let start = ''
    let value = item.data[typeKey]
    if (type === 'temperature' && unit === 'F') {
      value = (value * 9) / 5 + 32
    }

    // Format the date based on filterType
    if (filterType === 'date') {
      start = formateDate(item?.data?.dateTime, 'HH:mm a')
    } else {
      start = formateDate(item?.data?.dateTime, 'LLL dd')
    }
    const groupKey = `${item.userId._id}-${start}`
      .replace(/\s+/g, '')
      .toLowerCase()

    if (filterType === 'date') {
      groupedData.set(groupKey, {
        userId: item.userId._id,
        value,
        time: start,
      })
    } else {
      // Group by formatted date (`start`) and calculate min/max
      if (!groupedData.has(groupKey)) {
        groupedData.set(groupKey, {
          userId: item.userId._id,
          min: value,
          max: value,
          total: Number(value),
          time: start,
        })
      } else {
        const existing = groupedData.get(groupKey)
        existing.min = Math.min(existing.min, value)
        existing.max = Math.max(existing.max, value)
        existing.total = (Number(existing.total) || 0) + Number(value)
        groupedData.set(groupKey, existing)
      }
    }
  })

  return Array.from(groupedData.values()).sort(
    (a, b) => new Date(a.dateTime) - new Date(b.dateTime),
  )
}

export const transformAndGroupData = (data, filterType = 'date') => {
  const uniqueUserIds = new Set()
  const groupedData = data.reduce((acc, item) => {
    const {
      time: test_time,
      userId: user_id,
      value,
      min: min_value,
      max: max_value,
      total: total_value,
    } = item

    uniqueUserIds.add(user_id)

    // Find or create the time group
    let timeGroup = acc.find((entry) => entry.time === test_time)
    if (!timeGroup) {
      timeGroup = { time: test_time }
      acc.push(timeGroup)
    }

    // Add the user's value for this time
    if (filterType === 'date') {
      timeGroup[user_id] = value
    } else {
      timeGroup[user_id] = {
        min_value: min_value,
        max_value: max_value,
        total_value: total_value,
      }
    }

    return acc.sort((a, b) => new Date(a.time) - new Date(b.time))
  }, [])

  // Step 2: Sort by `time` in ascending order
  // groupedData.sort((a, b) => new Date(a.time) - new Date(b.time))
  const userIdArray = Array.from(uniqueUserIds)
  return { groupedData, userIdArray }
}

export const transformMutlipleDaysData = (rawData) => {
  return rawData.map((entry) => {
    const transformedEntry = { time: entry.time }
    Object.keys(entry).forEach((key) => {
      if (key !== 'time') {
        transformedEntry[`${key}_min`] = parseFloat(entry[key].min_value)
        transformedEntry[`${key}_max`] = parseFloat(entry[key].max_value)
        transformedEntry[`${key}_total`] = parseFloat(entry[key].total_value)
      }
    })
    return transformedEntry
  })
}

export const filterSleepDataByType = (data, filterType) => {
  if (!data || data.length === 0) {
    return []
  }

  const groupedSleepData = new Map() // Map to store grouped sleep values

  data
    ?.filter((item) => item.type === 'sleep')
    .forEach((item) => {
      const { dateTime, arraySleep, sleepUnitLength } = item.data

      const startTime = new Date(dateTime)
      const sleepValues = arraySleep.split(' ').map(Number) // Convert sleep status to numbers
      const interval = parseInt(sleepUnitLength, 10) || 1 // Minutes to increment

      sleepValues.forEach((stage, index) => {
        const sleepStage = getSleepStageByInterval(stage, interval)

        const currentTime = new Date(startTime)
        currentTime.setMinutes(startTime.getMinutes() + index * interval)

        let start = ''
        if (filterType === 'date') {
          start = formateDate(currentTime.toISOString(), 'HH:mm a')
        } else {
          start = formateDate(currentTime.toISOString(), 'yyyy-MM-dd HH:mm:ss')
        }

        // Store sleep data as individual time-based values
        groupedSleepData.set(currentTime.toISOString(), {
          userId: item.userId._id,
          stage: sleepStage,
          time: start,
          orignalStartTime: formateDate(
            currentTime.toISOString(),
            'yyyy-MM-dd HH:mm:ss',
          ),
        })
      })
    })

  return Array.from(groupedSleepData.values()).sort(
    (a, b) => new Date(a.orignalStartTime) - new Date(b.orignalStartTime),
  )
}
