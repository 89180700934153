import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ProtectedRoute from './core/components/ProtectedRoute'
import MainLayout from './core/components/layouts/Twocolumn/MainLayout'

import Community from './pages/Community'
import { AuthProvider } from './hooks/AuthContext'
import { AppStateProvider } from './hooks/AppStateContext'
// import Dashboard from './pages/Dashboard'
import LoginSuccess from './pages/LoginSuccess'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import NotFound from './pages/NotFound'
import Verficiation from './pages/Verification'
import HealthDashboard from './pages/Health'
import Profile from './pages/Profile'
import Users from './pages/Users'
import Devices from './pages/Devices'

const App = () => {
  return (
    <AppStateProvider>
      <AuthProvider>
        <Router>
          <Routes>
            <Route>
              <Route path="/login-success" element={<LoginSuccess />} />
              <Route path="/signin" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/verification" element={<Verficiation />} />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <MainLayout>
                      <HealthDashboard />
                    </MainLayout>
                  </ProtectedRoute>
                }
              />
              {/*
            Comment old dashboard its replaced by health dashboard.
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            /> */}
              <Route
                path="/community"
                element={
                  <ProtectedRoute>
                    <Community />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users"
                element={
                  <ProtectedRoute>
                    <MainLayout>
                      <Users />
                    </MainLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/devices"
                element={
                  <ProtectedRoute>
                    <MainLayout>
                      <Devices />
                    </MainLayout>
                  </ProtectedRoute>
                }
              />

              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </AppStateProvider>
  )
}

export default App
