import Spinner from 'react-bootstrap/Spinner'
import { Container, Row, Col } from 'react-bootstrap'

const SmallLoader = () => {
  return (
    <Container
      className="d-flex justify-content-center 
    align-items-center "
      fluid
    >
      <Row>
        <Col className="col-md-12 text-center">
          <Spinner
            animation="border"
            style={{ width: '2rem', height: '2rem' }}
          />
          <span className="visually-hidden">Loading...</span>
        </Col>
      </Row>
    </Container>
  )
}

export default SmallLoader
