import React, { useState, useMemo } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'

const PopoverComponent = ({
  children,
  content,
  placement = 'right',
  className = '',
  offset = [0, 15],
}) => {
  const [show, setShow] = useState(false)
  // Memoize the popover to prevent unnecessary re-renders
  const popover = useMemo(
    () => (
      <Popover
        id="popover-basic"
        className={className}
        style={{ width: 'auto', maxWidth: 'none' }}
        onMouseEnter={() => setShow(true)} // Keep popover open on hover
        onMouseLeave={() => setShow(false)} // Hide on leave
      >
        <Popover.Body>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Popover.Body>
      </Popover>
    ),
    [content, className],
  )

  return (
    <OverlayTrigger
      show={show}
      placement={placement}
      overlay={popover}
      delay={{ show: 50, hide: 300 }} // Increased hide delay to allow hover on popover
      rootClose={false} // Prevent popover from closing on click outside
      popperConfig={{
        modifiers: [{ name: 'offset', options: { offset } }],
      }}
    >
      <span
        onMouseEnter={() => setShow(true)} // Show popover on hover
        onMouseLeave={() => setShow(false)} // Hide popover on leave
      >
        {children}
      </span>
    </OverlayTrigger>
  )
}

export default PopoverComponent
