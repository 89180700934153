import {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  Fragment,
} from 'react'
import { Button, Image } from 'react-bootstrap'
import { AuthContext } from '../../../hooks/AuthContext'
import {
  getGroupUserMaxAndMin,
  getGroupUserAverage,
  getGroupUserTotal,
} from '../../../core/utils/aggregation.js'
import { getUserName } from '../../../core/utils/user'

const Average = ({
  selectedUserIds,
  communityUsers,
  groupedData,
  type,
  filter,
  cardType,
  unit = '',
}) => {
  const { user } = useContext(AuthContext)
  const scrollContainerRef = useRef(null)
  const [isOverflowing, setIsOverflowing] = useState(false)

  // Debounce overflow check
  const checkOverflow = useCallback(() => {
    const container = scrollContainerRef.current
    if (container) {
      setIsOverflowing(container.scrollWidth > container.clientWidth)
    }
  }, [])

  useEffect(() => {
    checkOverflow()
    const handleResize = () => checkOverflow()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [checkOverflow])

  // Memoized operation result calculation
  const getOperationResult = useCallback(
    (data, user, type) => {
      switch (type) {
        case 'range':
          return getGroupUserMaxAndMin(data, user.user_id, cardType, filter)
        case 'average':
          return getGroupUserAverage(data, user.user_id, cardType, filter)
        case 'total':
          return getGroupUserTotal(data, user.user_id, cardType, filter)
        default:
          return null
      }
    },
    [cardType, filter],
  )

  // Memoized user data processing
  const userDataList = useMemo(() => {
    if (!user?.user_id) return []

    const data = getOperationResult(groupedData, user, type)
    const baseData = {
      id: user.user_id,
      name: getUserName(user),
      data,
    }

    const selectedUsersData = communityUsers
      .filter((communityUser) =>
        selectedUserIds.includes(communityUser.user_id),
      )
      .map((communityUser) => ({
        id: communityUser.user_id,
        name: getUserName(communityUser),
        data: getOperationResult(groupedData, communityUser, type),
      }))

    return [baseData, ...selectedUsersData]
  }, [
    user,
    getOperationResult,
    groupedData,
    type,
    communityUsers,
    selectedUserIds,
  ])

  // Scroll Function
  const scroll = (direction) => {
    const container = scrollContainerRef.current
    container?.scrollBy({
      left: direction === 'left' ? -200 : 200,
      behavior: 'smooth',
    })
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30 }}>
      {isOverflowing && (
        <Button
          variant="light"
          onClick={() => scroll('left')}
          style={{
            cursor: 'pointer',
            color: '#000',
            backgroundColor: 'transparent',
            padding: '10px',
            rotate: '180deg',
            border: 'none',
          }}
        >
          <Image src="/images/right-arrow.svg" />
        </Button>
      )}

      <div
        ref={scrollContainerRef}
        style={{
          display: 'flex',
          overflowX: 'auto',
          scrollBehavior: 'smooth',
          whiteSpace: 'nowrap',
          scrollbarWidth: 'none', // Firefox
          msOverflowStyle: 'none', // IE/Edge
        }}
        className="hide-scrollbar"
      >
        {userDataList.map(({ id, name, data }, index) => (
          <Fragment key={`user-${id}`}>
            {index !== 0 && (
              <div
                style={{
                  margin: '0px 20px',
                  width: '1px',
                  border: '1px #EFEFEF solid',
                  color: '#fff',
                }}
              >
                |
              </div>
            )}
            <div>
              <div className="health-card-username">{name}</div>
              <div className="healthCardAverage">
                {data === 0 || (data?.min === 0 && data?.max === 0)
                  ? '--'
                  : type === 'range'
                    ? `${data?.min} - ${data?.max} BPM`
                    : type === 'total'
                      ? `${data} Steps`
                      : `Average ${data}`}
                {((unit && data > 0) || data?.min > 0 || data?.max > 0) &&
                  `\u00B0 ${unit}`}
              </div>
            </div>
          </Fragment>
        ))}
      </div>

      {isOverflowing && (
        <Button
          variant="light"
          onClick={() => scroll('right')}
          style={{
            cursor: 'pointer',
            color: '#000',
            backgroundColor: 'transparent',
            padding: '10px',
            border: 'none',
          }}
        >
          <Image src="/images/right-arrow.svg" />
        </Button>
      )}
    </div>
  )
}

export default Average
