import { useState, useContext, useMemo, useCallback } from 'react'
import { AuthContext } from '../../../hooks/AuthContext'
import { Card, Button } from 'react-bootstrap'
import { AreaChart } from './ChartJs/AreaChart'
import { LineChart } from './ChartJs/LineChart'
import Average from './Average'
import {
  filterDataByType,
  transformAndGroupData,
} from '../../../core/utils/graphs'

const TemperatureCard = ({ data, selectedUserIds, communityUsers, filter }) => {
  const { user: currentUser } = useContext(AuthContext)
  const [unit, setUnit] = useState('C') // Default to Celsius

  // Toggle unit between Celsius and Fahrenheit
  const toggleUnit = useCallback(() => {
    setUnit((prevUnit) => (prevUnit === 'C' ? 'F' : 'C'))
  }, [])

  const ChartComponent = useMemo(
    () => (filter.type === 'date' ? AreaChart : LineChart),
    [filter.type],
  )

  // Memoize filtered and transformed data to avoid unnecessary reprocessing
  const { groupedData, userIdArray } = useMemo(() => {
    const temperatureData = filterDataByType(
      data,
      'temperature',
      'temperature',
      filter.type,
      unit,
    )
    return transformAndGroupData(temperatureData, filter.type)
  }, [unit, data, filter.type])

  return (
    <Card className="healthCardContainer">
      <Card.Body>
        <Card.Title className="tempratureTitle">
          <span className="tempratureLabel">
            <div className="healthCardTitle">Body Temperature</div>
            <Average
              selectedUserIds={selectedUserIds}
              communityUsers={communityUsers}
              groupedData={groupedData}
              type="average"
              filter={filter}
              cardType="temperature"
              unit={unit}
            />
          </span>
          <Button variant="" onClick={toggleUnit} className="tempratureToggle">
            <span
              className={`tempratureToggleButtons ${
                unit !== 'C' ? 'tempratureToggleActive' : ''
              }`}
            >
              F
            </span>
            <span
              style={{ padding: '2px 5px', cursor: 'pointer', margin: '0 2px' }}
              className={`tempratureToggleButtons ${
                unit === 'C' ? 'tempratureToggleActive' : ''
              }`}
            >
              C
            </span>
          </Button>
        </Card.Title>

        {groupedData.length > 0 ? (
          <ChartComponent
            data={groupedData}
            userIdArray={userIdArray}
            communityUsers={communityUsers}
            currentUser={currentUser}
            labelUnit={unit === 'C' ? ' °C' : ' °F'}
          />
        ) : (
          <div className="noData">No data available for the selected date</div>
        )}
      </Card.Body>
    </Card>
  )
}

export default TemperatureCard
