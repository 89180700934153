import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'react-bootstrap'
import TopBar from '../core/components/layouts/TopBar'

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <Container className="common-pages">
      <TopBar className={'topnav text-center '} />
      <Container
        className="d-flex justify-content-center 
    align-items-center  mt-5"
        fluid
      >
        <Row>
          <Col className="col-md-12 text-center">
            <h1 className="dashboard-text">{t('notFound.code')}</h1>
            <p className="dashboard-subtext">{t('notFound.title')}</p>
            <p className="dashboard-subtext">{t('notFound.message')}</p>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
export default NotFound
