import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../hooks/AuthContext'

const LoginSuccess = () => {
  const { login, isAppLoading } = useContext(AuthContext)
  const [token, setToken] = useState('')
  const navigate = useNavigate()

  // if code in query then get token from query and set in context
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')

    if (code && code.length > 0) {
      setToken(code)
    }
  }, [])

  useEffect(() => {
    if (token && token.length > 0) {
      login(token)
      if (!isAppLoading) {
        navigate('/')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isAppLoading])

  if (isAppLoading) {
    return <div>Loading...</div>
  }
  return null
}
export default LoginSuccess
