export const getFromLocalStorage = (key) => {
  return localStorage.getItem(key) // Retrieve value from localStorage
}

export const setFromLocalStorage = (key, value) => {
  localStorage.setItem(key, value) // Save valuen to localStorage
}

export const clearFromLocalStorage = (key) => {
  localStorage.removeItem(key) // Clear value from localStorage
}
