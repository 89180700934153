import Container from 'react-bootstrap/Container'
import SideBar from './SideBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../../../scss/health.scss'

const MainLayout = ({ children }) => {
  return (
    <Container fluid className="m-0 p-0">
      <Row className="m-0 p-0 gx-0 ">
        <Col className="sidebar m-0 p-0 ">
          <SideBar />
        </Col>
        <Col className="m-0 p-0">{children}</Col>
      </Row>
    </Container>
  )
}

export default MainLayout
