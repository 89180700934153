import { Image, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const SignupWidgetRightTop = () => {
  const { t } = useTranslation()
  return (
    <Container
      className="signup-widget-right-top "
      style={{
        backgroundImage: 'url(images/signup-right.svg)',
        backgroundSize: 'cover',
      }}
    >
      <div style={{ maxWidth: '350px' }} className="mx-auto">
        <Image
          src="images/rahulp.webp"
          width={86}
          height={86}
          alt="@Rahul P"
          style={{ borderRadius: '86px' }}
        />

        <p className="quote-text">
          <Image
            src="images/quote-open.svg"
            alt="quote-open"
            style={{ position: 'relative', top: '-10px', right: '5px' }}
          />
          {t('signup.rightPanel.quote')}
          <Image
            src="images/quote-close.svg"
            alt="quote-close"
            style={{ position: 'relative', top: '10px', left: '3px' }}
          />
        </p>

        <p className="top-widjet-author">@Rahul P</p>
        <p className="top-widjet-author-designation">
          Head of partnership, ekincare
        </p>
      </div>
    </Container>
  )
}
export default SignupWidgetRightTop
