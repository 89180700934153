import SignupWidgetRightTop from './SignupWidgetRightTop'
import SignupWidgetRightBottom from './SignupWidgetRightBottom'
const SignupLeftWidjet = () => {
  return (
    <div className="signup-right-widjet-container">
      <SignupWidgetRightTop />
      <SignupWidgetRightBottom />
    </div>
  )
}
export default SignupLeftWidjet
