import { createContext, useState, useEffect } from 'react'

import { apiGet } from '../core/api.js'
import {
  getFromLocalStorage,
  setFromLocalStorage,
  clearFromLocalStorage,
} from '../core/utils/localStorageService.js'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)
  const [isAppLoading, setIsAppLoading] = useState(true)

  // if user request refresh browser then get token from localstorage
  useEffect(() => {
    const storedToken = getFromLocalStorage('token')

    if (storedToken && storedToken.length > 0 && storedToken !== token) {
      setToken(storedToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Fetch the user when the app loads
  useEffect(() => {
    if (token && token.length > 0) {
      loadCurrentUser()
    } else {
      setIsAppLoading(false)
    }
  }, [token])

  const loadCurrentUser = async () => {
    setIsAppLoading(true)
    apiGet('user')
      .then((res) => {
        setUser(res.data.data)
      })
      .catch(() => {
        setUser(null)
        clearFromLocalStorage('token')
      })
      .finally(() => {
        setIsAppLoading(false) // Always stop loading after attempt
      })
  }

  const login = async (token) => {
    setIsAppLoading(true)
    setToken(token)
    setFromLocalStorage('token', token)
  }
  const redirectToGoogle = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/oauth/google`
  }

  const logout = async () => {
    clearFromLocalStorage('token')
    setToken(null)
    setUser(null)
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        redirectToGoogle,
        login,
        logout,
        isAppLoading,
        token,
        loadCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
