import axios from 'axios'
import {
  clearFromLocalStorage,
  getFromLocalStorage,
} from './utils/localStorageService'

// Create an Axios instance
const axiosInstance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/`, // Set your API base URL
})

// Request interceptor: Adds token to every request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getFromLocalStorage('token') // Retrieve token from localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

// Response interceptor: Retry failed requests and handle token expiration
axiosInstance.interceptors.response.use(
  (response) => response, // If the response is successful, return it
  (error) => {
    // Log out if the token is expired or invalid (401 Unauthorized)
    if (error.response && error.response.status === 401) {
      clearFromLocalStorage('token') // Clear token from storage
      // Referesh and redirect to base page
      window.location.href = '/'
    }

    // Retry for network errors or other non-401 errors
    // const originalRequest = error.config
    // if (
    //   !originalRequest._retry &&
    //   error.response &&
    //   error.response.status !== 401
    // ) {
    //   originalRequest._retry = true // Avoid infinite retries
    //   return axiosInstance(originalRequest) // Retry the request
    // }

    return Promise.reject(error) // Reject other errors
  },
)

export default axiosInstance
