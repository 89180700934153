import { useContext, useMemo } from 'react'
import { AuthContext } from '../../../hooks/AuthContext'
import { Card } from 'react-bootstrap'
import { AreaChart } from './ChartJs/AreaChart'
import { LineChart } from './ChartJs/LineChart'

import {
  filterDataByType,
  transformAndGroupData,
} from '../../../core/utils/graphs'
import Average from './Average'

const HeartRateCard = ({ data, selectedUserIds, communityUsers, filter }) => {
  const { user: currentUser } = useContext(AuthContext)

  // Memoize heart rate data to avoid recalculating on every render
  const heartRateData = useMemo(
    () => filterDataByType(data, 'heart_rate', 'heartRate', filter.type),
    [data, filter.type],
  )

  // Memoize transformed and grouped data
  const { groupedData, userIdArray } = useMemo(
    () => transformAndGroupData(heartRateData, filter.type),
    [heartRateData, filter.type],
  )

  // Memoize chart type selection to avoid unnecessary re-renders
  const ChartComponent = useMemo(
    () => (filter.type === 'date' ? AreaChart : LineChart),
    [filter.type],
  )

  return (
    <Card className="healthCardContainer">
      <Card.Body>
        <Card.Title>
          <div className="healthCardTitle">Heart Rate</div>
          <Average
            selectedUserIds={selectedUserIds}
            communityUsers={communityUsers}
            groupedData={groupedData}
            type="range"
            filter={filter}
            cardType="heartRate"
          />
        </Card.Title>
        {heartRateData && heartRateData.length > 0 ? (
          <ChartComponent
            data={groupedData}
            userIdArray={userIdArray}
            communityUsers={communityUsers}
            currentUser={currentUser}
            labelUnit=" bpm"
          />
        ) : (
          <div className="noData">No data available for the selected date</div>
        )}
      </Card.Body>
    </Card>
  )
}

export default HeartRateCard
