import { useContext, useMemo, useCallback } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'
import { AuthContext } from '../../../../hooks/AuthContext'
import 'bootstrap/dist/css/bootstrap.min.css'
import PopoverComponent from './PopoverComponent'

const SideBar = () => {
  const { logout } = useContext(AuthContext)
  const { pathname } = useLocation()

  // Utility to get className based on path
  const getClassName = useCallback(
    (path) => (pathname === path ? 'sidebar-selected-menu' : 'sidebar-menu'),
    [pathname],
  )

  // Memoize menu items to prevent recalculation
  const menuItems = useMemo(
    () => [
      {
        key: 'dashboard',
        path: '/',
        image: `/images/sidebar/${
          pathname === '/' ? 'selected/home.svg' : 'home.svg'
        }`,
        content: 'Dashboard',
        className: getClassName('/'),
      },
      {
        key: 'users',
        path: '/users',
        image: `/images/sidebar/${
          pathname === '/users' ? 'selected/user.svg' : 'user.svg'
        }`,
        content: 'Users',
        className: getClassName('/users'),
      },
      {
        key: 'devices',
        path: '/devices',
        image: `/images/sidebar/${
          pathname === '/devices' ? 'selected/device.svg' : 'device.svg'
        }`,
        content: 'Devices (Coming Soon)',
        className: '', // Optional or add dynamic logic if needed later
      },
      {
        key: 'logout',
        action: logout,
        image: '/images/sidebar/logout.svg',
        content: 'Logout',
        className: 'sidebar-menu',
      },
    ],
    [pathname, logout, getClassName],
  )

  return (
    <div className="sidebar-container">
      <ListGroup variant="flush" className="sidebar-list-group">
        <ListGroup.Item className="p-0 m-0 list-group-item-home">
          <Link to="/">
            <img
              className="sidebar-icons sidebar-home-icon"
              alt="Home"
              src="/images/logo.svg"
            />
          </Link>
        </ListGroup.Item>
        {menuItems.map(({ key, path, action, image, content, className }) => (
          <ListGroup.Item className="p-0 m-0" key={key}>
            <PopoverComponent
              content={content}
              placement="right"
              className="sidebar-menu-tooltip"
            >
              {action ? (
                <Link onClick={action}>
                  <img alt={content} className={className} src={image} />
                </Link>
              ) : (
                <Link to={path}>
                  <img alt={content} className={className} src={image} />
                </Link>
              )}
            </PopoverComponent>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  )
}

export default SideBar
