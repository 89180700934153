import { Form } from 'react-bootstrap'

const FormSelect = ({ label, name, value, onChange, error, options }) => (
  <Form.Group controlId={`form${name}`}>
    <Form.Label className="txt-label">{label}</Form.Label>
    <Form.Select
      name={name}
      value={value}
      onChange={onChange}
      className={error ? 'error-txt-field' : 'txt-field'}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Form.Select>
    {error && <p className="error-msg">{error}</p>}
  </Form.Group>
)

export default FormSelect
