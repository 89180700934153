import { useContext, useEffect } from 'react'
import { AuthContext } from '../hooks/AuthContext'
import { useNavigate } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import TopBar from '../core/components/layouts/TopBar'
import DashboardMessage from '../features/dashboard/DashboardMessage'
import Loader from '../core/components/Loader'

const Dashboard = () => {
  const { user, isAppLoading } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (
      !isAppLoading &&
      user &&
      (user.company_name === null || user.company_name === '')
    ) {
      navigate('/company')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppLoading, user])

  if (isAppLoading) {
    return <Loader />
  }

  return (
    <>
      {user && (
        <Container
          className="dashboard-form-container mx-auto "
          fluid
          style={{
            margin: 0,
            padding: 0,
            backgroundImage: 'url(images/Background.svg)',
            backgroundSize: 'cover',
          }}
        >
          <TopBar className={'topnav'} showLogout={true} />
          <DashboardMessage />
        </Container>
      )}
    </>
  )
}

export default Dashboard
