export const getUserNameFromCommunityArray = (userId, communityUsers) => {
  const user = communityUsers.find((user) => user._id === userId)
  return getUserName(user)
}

export const getUserName = (user) => {
  const userName = user
    ? user?.firstName
      ? user?.firstName
      : user?.email?.substring(0, user?.email.lastIndexOf('@'))
    : ''
  // Capitalize first letter
  return userName.charAt(0).toUpperCase() + userName.slice(1)
}
